// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as Dealer_Search_Types$DvmAdminFrontendFr from "./Dealer_Search_Types.bs.js";

function fetchDealerSearch(submissionCallbacks, userManager, setResult, queryParams, showHistory, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Dealer_Search_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/dealers/search/" + (
          showHistory ? "true" : "false"
        ) + "" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchDealerSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForLocalitySelect(alert, userManager, setLocations, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/dealer/locality").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var locations = result._0;
          return Curry._1(setLocations, (function (param) {
                        return locations;
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setLocations, (function (param) {
                      return /* [] */0;
                    }));
      });
}

function managerTwoParams_encode(v) {
  return Js_dict.fromArray([
              [
                "paramOne",
                Decco.optionToJson(Decco.stringToJson, v.paramOne)
              ],
              [
                "paramTwo",
                Decco.optionToJson(Decco.stringToJson, v.paramTwo)
              ]
            ]);
}

function managerTwoParams_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var paramOne = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "paramOne"), null));
  if (paramOne.TAG === /* Ok */0) {
    var paramTwo = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "paramTwo"), null));
    if (paramTwo.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                paramOne: paramOne._0,
                paramTwo: paramTwo._0
              }
            };
    }
    var e = paramTwo._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".paramTwo" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = paramOne._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".paramOne" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function managerTwoParamList_encode(v) {
  return Decco.listToJson(managerTwoParams_encode, v);
}

function managerTwoParamList_decode(v) {
  return Decco.listFromJson(managerTwoParams_decode, v);
}

function queryForManagerSelect(alert, userManager, setManagers, selectedRole) {
  Curry._1(setManagers, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", managerTwoParamList_decode, undefined, userManager, selectedRole, "/dropdown/dealer/managers").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var managers = result._0;
          return Curry._1(setManagers, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: managers
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setManagers, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  fetchDealerSearch ,
  queryForLocalitySelect ,
  managerTwoParams_encode ,
  managerTwoParams_decode ,
  managerTwoParamList_encode ,
  managerTwoParamList_decode ,
  queryForManagerSelect ,
}
/* react Not a pure module */
