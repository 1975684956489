// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Orders_Modal$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Modal.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function cleanAgentCodes(agentCodes, dealerIds) {
  return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(agentCodes, /* [] */0, (function (x) {
                return Belt_List.map(Belt_List.concatMany(Belt_List.toArray(Belt_List.map(Belt_List.keep(x, (function (x) {
                                              if (Belt_List.length(dealerIds) > 0) {
                                                return Belt_List.has(dealerIds, x.parent, (function (a, b) {
                                                              return a === b;
                                                            }));
                                              } else {
                                                return true;
                                              }
                                            })), (function (x) {
                                          return Belt_List.keep(x.child, (function (x) {
                                                        return x.paramOne !== "000";
                                                      }));
                                        })))), (function (x) {
                              return {
                                      label: {
                                        TAG: /* String */3,
                                        _0: x.paramOne + Belt_Option.mapWithDefault(x.paramTwo, "", (function (x) {
                                                return " - " + x;
                                              }))
                                      },
                                      value: x.paramOne
                                    };
                            }));
              }));
}

function Order_Search_Modal_Dealer_Filters(Props) {
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var dealerGroups = Props.dealerGroups;
  var fleetManagers = Props.fleetManagers;
  var lldManagers = Props.lldManagers;
  var agentCodes = Props.agentCodes;
  var salesmanNames = Props.salesmanNames;
  var dealerIds = Props.dealerIds;
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 3
            }, React.createElement(Core.Grid, {
                  alignItems: "flex-end",
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                        onChange: Curry._1(formAdditional.updateDealerName, (function (input, dealerName) {
                                var newrecord = Caml_obj.obj_dup(input);
                                newrecord.dealerName = dealerName;
                                return newrecord;
                              })),
                        value: formAdditional.input.dealerName,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                        label: Messages_Orders_Modal$DvmAdminFrontendFr.dealerName
                      }),
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Orders_Modal$DvmAdminFrontendFr.dealerGroup,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: formAdditional.input.dealerGroup,
                            onChange: Curry._1(formAdditional.updateDealerGroup, (function (input, dealerGroup) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.dealerGroup = dealerGroup;
                                    return newrecord;
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(dealerGroups, /* [] */0)
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.agent,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: form.input.agentCode,
                            onChange: Curry._1(form.updateAgentCode, (function (input, agentCode) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.agentCode = agentCode;
                                    return newrecord;
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Labeled */1,
                          _0: cleanAgentCodes(agentCodes, dealerIds)
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.seller,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: form.input.salesmanName,
                            onChange: Curry._1(form.updateSalesmanName, (function (input, salesmanName) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.salesmanName = salesmanName;
                                    return newrecord;
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(salesmanNames, /* [] */0)
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Orders_Modal$DvmAdminFrontendFr.keyAccountManagerID,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: formAdditional.input.keyAccountManagerID,
                            onChange: Curry._1(formAdditional.updateKeyAccountManagerID, (function (input, keyAccountManagerID) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.keyAccountManagerID = keyAccountManagerID;
                                    return newrecord;
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(fleetManagers, /* [] */0)
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Orders_Modal$DvmAdminFrontendFr.sectorManagerID,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: formAdditional.input.sectorManagerID,
                            onChange: Curry._1(formAdditional.updateSectorManagerID, (function (input, sectorManagerID) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.sectorManagerID = sectorManagerID;
                                    return newrecord;
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(lldManagers, /* [] */0)
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }));
}

var InputStyles;

var make = Order_Search_Modal_Dealer_Filters;

export {
  InputStyles ,
  cleanAgentCodes ,
  make ,
}
/* react Not a pure module */
