// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Version_Types.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.versionLabel, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.versionLabelCommercial),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.customBonus, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.amount),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.isCustomFEValue, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.nonFleetCustomAmount),
        tl: /* [] */0
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function displayCustomBonus(customBonus, flash, intl) {
  var amount = flash.amount;
  if (amount === undefined) {
    return "0";
  }
  var match = amount.numberType;
  var match$1 = amount.value;
  if (match === undefined) {
    return "0";
  }
  if (match$1 === undefined) {
    return "0";
  }
  var finalAmount = Belt_Option.mapWithDefault(customBonus, match$1, (function (x) {
          if (x > 0.0) {
            return x;
          } else {
            return match$1;
          }
        }));
  switch (match) {
    case /* PERCENT */0 :
        return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, finalAmount);
    case /* EURO */1 :
        return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, finalAmount);
    case /* NOTSET */2 :
        return String(finalAmount);
    
  }
}

function isAmountCustomLabel(customBonus, intl) {
  var message = Belt_Option.mapWithDefault(customBonus, false, (function (x) {
          return x > 0.0;
        })) ? Messages_Common$DvmAdminFrontendFr.yes : Messages_Common$DvmAdminFrontendFr.no;
  return intl.formatMessage(message);
}

function Flash_Edit_NonFleet_Version_Table(Props) {
  var tablePage = Props.tablePage;
  var tableHandler = Props.tableHandler;
  var filterPagingAndSort = Props.filterPagingAndSort;
  var flash = Props.flash;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (param) {
                                  var customBonus = param.customBonus;
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: param.model,
                                                  tl: {
                                                    hd: param.version,
                                                    tl: {
                                                      hd: Belt_Option.getWithDefault(param.versionLabel, ""),
                                                      tl: {
                                                        hd: displayCustomBonus(customBonus, flash, intl),
                                                        tl: {
                                                          hd: isAmountCustomLabel(customBonus, intl),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: tableHandler.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._1(filterPagingAndSort, {
          page: tableHandler.page,
          headers: headers,
          brand: tableHandler.brand,
          model: tableHandler.model,
          version: tableHandler.version,
          versionLabel: tableHandler.versionLabel,
          engineType: tableHandler.engineType,
          modelPhase: tableHandler.modelPhase
        });
  };
  var handleChangePage = function (page) {
    Curry._1(filterPagingAndSort, {
          page: page,
          headers: tableHandler.headers,
          brand: tableHandler.brand,
          model: tableHandler.model,
          version: tableHandler.version,
          versionLabel: tableHandler.versionLabel,
          engineType: tableHandler.engineType,
          modelPhase: tableHandler.modelPhase
        });
  };
  var handleRowsPerPageChange = function (param) {
    Curry._1(filterPagingAndSort, {
          page: 0,
          headers: tableHandler.headers,
          brand: tableHandler.brand,
          model: tableHandler.model,
          version: tableHandler.version,
          versionLabel: tableHandler.versionLabel,
          engineType: tableHandler.engineType,
          modelPhase: tableHandler.modelPhase
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: tableHandler.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined)
                }));
}

var make = Flash_Edit_NonFleet_Version_Table;

export {
  headersConfiguration ,
  displayCustomBonus ,
  isAmountCustomLabel ,
  make ,
}
/* headersConfiguration Not a pure module */
