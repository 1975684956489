// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Assets$DvmAdminFrontendFr from "../../assets/Assets.bs.js";
import * as EnvVar$DvmAdminFrontendFr from "../../bindings/global/EnvVar.bs.js";
import * as App_Api$DvmAdminFrontendFr from "../../common/App_Api.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../common/components/icons/App_Icon.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../common/utils/Utils_Date.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../common/components/App_Checkbox.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../common/types/App_Types_Date.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../common/types/App_Types_Status.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../context/user/User_Types_Utils.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../common/components/App_OverlaySpinner.bs.js";
import * as Bonuses_Search_Api$DvmAdminFrontendFr from "./Bonuses_Search_Api.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../common/components/input/App_SelectFormInput.bs.js";
import * as App_CompanyFormInput$DvmAdminFrontendFr from "../../common/components/input/App_CompanyFormInput.bs.js";
import * as Messages_Bonuses_List$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_List.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Search$DvmAdminFrontendFr from "../../intl/messages/orders/Messages_Orders_Search.bs.js";
import * as Messages_Bonuses_Manage$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_Manage.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../common/components/input/App_DoubleSelectFormInput.bs.js";
import * as Bonuses_Search_Filter_Utils$DvmAdminFrontendFr from "./Bonuses_Search_Filter_Utils.bs.js";
import * as Bonuses_Search_Modal_Wrapper$DvmAdminFrontendFr from "./Bonuses_Search_Modal_Wrapper.bs.js";

var modalButton = Css.css(Object.assign({}, {
          color: Theme_Colors$DvmAdminFrontendFr.black,
          height: "48px",
          width: "48px"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayMain
          }
        }));

var icon = Css.css({
      fontWeight: "bold"
    });

var iconSmall = Css.css({
      bottom: "8px",
      fontWeight: "bold",
      position: "absolute",
      right: "3px"
    });

function Bonuses_Search_Filter(Props) {
  var form = Props.form;
  var formModal = Props.formModal;
  var formAdditional = Props.formAdditional;
  var formAdditionalSecond = Props.formAdditionalSecond;
  var formModalVO = Props.formModalVO;
  var operation = Props.operation;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return "";
      });
  var setDealerSearchInput = match[1];
  var dealerSearchInput = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setModal = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var modalPending = match$2[0];
  var match$3 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$3[1];
  var match$4 = match$3[0];
  var bonusesPERFFilter = match$4.bonusesPERFFilter;
  var bonusesModalFilter = match$4.bonusesModalFilter;
  var bonusesFilter = match$4.bonusesFilter;
  var alert = ReactAlert.useAlert();
  var match$5 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$6 = match$5[0];
  var userManager = match$6.userManager;
  var selectedRole = match$6.selectedRole;
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var match$8 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealers = match$8[1];
  var drAndDealers = match$8[0];
  var match$9 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$9[1];
  var match$10 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionTypesFleet = match$10[1];
  var match$11 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionTypesNonFleet = match$11[1];
  var actionTypesNonFleet = match$11[0];
  var match$12 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersionsFleet = match$12[1];
  var modelVersionsFleet = match$12[0];
  var match$13 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersionsNonFleet = match$13[1];
  var modelVersionsNonFleet = match$13[0];
  var match$14 = React.useState(function () {
        return /* [] */0;
      });
  var setStatuses = match$14[1];
  var match$15 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionsFleet = match$15[1];
  var actionsFleet = match$15[0];
  var match$16 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionsNonFleet = match$16[1];
  var actionsNonFleet = match$16[0];
  var refreshDealers = function (param) {
    App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealers, form.input.showDealerHistory, selectedRole);
  };
  React.useEffect((function () {
          refreshDealers(undefined);
        }), [form.input.showDealerHistory]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(drAndDealers, undefined, (function (drAndDealers) {
                  var drKeep = Belt_List.keep(form.input.dr, (function (x) {
                          return Belt_List.some(drAndDealers, (function (dr) {
                                        return dr.parent === x;
                                      }));
                        }));
                  var dealerKeep = Belt_List.keep(form.input.dealerId, (function (x) {
                          return Belt_List.some(Belt_List.flatten(Belt_List.map(drAndDealers, (function (x) {
                                                return x.child;
                                              }))), (function (dr) {
                                        return dr === x;
                                      }));
                        }));
                  Curry._2(form.updateDealerId, (function (input, dealerId) {
                          return {
                                  dr: input.dr,
                                  dealerId: dealerId,
                                  orderId: input.orderId,
                                  budgetType: input.budgetType,
                                  actionType: input.actionType,
                                  actionId: input.actionId,
                                  status: input.status,
                                  model: input.model,
                                  version: input.version,
                                  budgetYear: input.budgetYear,
                                  showBonusesPlusHistory: input.showBonusesPlusHistory,
                                  maxAmount: input.maxAmount,
                                  isOverMaximum: input.isOverMaximum,
                                  orderType: input.orderType,
                                  siretPERF: input.siretPERF,
                                  showDealerHistory: input.showDealerHistory
                                };
                        }), dealerKeep);
                  Curry._2(form.updateDr, (function (input, dr) {
                          return {
                                  dr: dr,
                                  dealerId: input.dealerId,
                                  orderId: input.orderId,
                                  budgetType: input.budgetType,
                                  actionType: input.actionType,
                                  actionId: input.actionId,
                                  status: input.status,
                                  model: input.model,
                                  version: input.version,
                                  budgetYear: input.budgetYear,
                                  showBonusesPlusHistory: input.showBonusesPlusHistory,
                                  maxAmount: input.maxAmount,
                                  isOverMaximum: input.isOverMaximum,
                                  orderType: input.orderType,
                                  siretPERF: input.siretPERF,
                                  showDealerHistory: input.showDealerHistory
                                };
                        }), drKeep);
                }));
        }), [drAndDealers]);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchActionTypes(userManager, setActionTypesFleet, alert, selectedRole, "fleet");
          App_Api$DvmAdminFrontendFr.fetchActionTypes(userManager, setActionTypesNonFleet, alert, selectedRole, "nonfleet");
          Bonuses_Search_Api$DvmAdminFrontendFr.queryForStatuses(alert, userManager, setStatuses, selectedRole);
        }), []);
  React.useEffect((function () {
          Bonuses_Search_Filter_Utils$DvmAdminFrontendFr.updateNonFleetActionsAndModels(actionTypesNonFleet, operation, form, alert, userManager, setActionsNonFleet, setModelVersionsNonFleet, selectedRole);
        }), [actionTypesNonFleet]);
  React.useEffect((function () {
          if (Belt_Option.mapWithDefault(form.input.budgetYear, true, App_Types_Date$DvmAdminFrontendFr.isValid)) {
            var match = form.input.orderType;
            var exit = 0;
            switch (match) {
              case "SO" :
                  exit = 1;
                  break;
              case "CL" :
              case "PG" :
              case "RC" :
              case "RR" :
              case "VD" :
              case "VE" :
                  break;
              default:
                exit = 1;
            }
            if (exit === 1) {
              Bonuses_Search_Api$DvmAdminFrontendFr.getActions(alert, userManager, setActionsFleet, selectedRole, operation && selectedRole !== /* CompanyManager */2 ? (
                      form.input.actionType.length > 0 ? form.input.actionType : "PERF%5C,DERO%5C,REPR%5C,STOC"
                    ) : "PERF", Utils_Date$DvmAdminFrontendFr.toYearString(form.input.budgetYear), form.input.showBonusesPlusHistory, "fleet", undefined);
              Bonuses_Search_Api$DvmAdminFrontendFr.fetchModelVersionsFiltered(setModelVersionsFleet, userManager, selectedRole, Utils_Date$DvmAdminFrontendFr.toYearString(form.input.budgetYear), form.input.showBonusesPlusHistory, operation && selectedRole !== /* CompanyManager */2 ? (
                      form.input.actionType.length > 0 ? form.input.actionType : "PERF%5C,DERO%5C,REPR%5C,STOC"
                    ) : "PERF", "fleet");
            }
            Bonuses_Search_Filter_Utils$DvmAdminFrontendFr.updateNonFleetActionsAndModels(actionTypesNonFleet, operation, form, alert, userManager, setActionsNonFleet, setModelVersionsNonFleet, selectedRole);
          }
          
        }), [
        form.input.actionType,
        form.input.budgetYear,
        form.input.showBonusesPlusHistory
      ]);
  var submit = function (param) {
    Curry._1(setModal, (function (param) {
            return false;
          }));
    if (operation) {
      Curry._1(dispatch, {
            TAG: /* StoreBonusesFilter */14,
            _0: form.input
          });
      Curry._1(dispatch, {
            TAG: /* StoreBonusesModalFilter */15,
            _0: formModal.input
          });
      Curry._1(dispatch, {
            TAG: /* StoreBonusesModalAdditionalFilter */16,
            _0: formAdditional.input
          });
      Curry._1(dispatch, {
            TAG: /* StoreBonusesModalAdditionalSecondFilter */17,
            _0: formAdditionalSecond.input
          });
      Curry._1(dispatch, {
            TAG: /* StoreBonusesModalVOFilter */18,
            _0: formModalVO.input
          });
      Curry._1(form.submit, undefined);
      Curry._1(formModal.submit, undefined);
      Curry._1(formAdditional.submit, undefined);
      Curry._1(formAdditionalSecond.submit, undefined);
      return Curry._1(formModalVO.submit, undefined);
    } else {
      Curry._1(dispatch, {
            TAG: /* StoreBonusesPERFFilter */19,
            _0: form.input
          });
      return Curry._1(form.submit, undefined);
    }
  };
  React.useEffect((function () {
          if (operation) {
            if (Belt_Option.isSome(bonusesFilter) || Belt_Option.isSome(bonusesModalFilter)) {
              submit(undefined);
            }
            
          } else if (Belt_Option.isSome(bonusesPERFFilter)) {
            submit(undefined);
          }
          
        }), []);
  var tmp = {
    onChange: (function (val) {
        Curry._2(form.updateOrderId, (function (input, orderId) {
                return {
                        dr: input.dr,
                        dealerId: input.dealerId,
                        orderId: orderId,
                        budgetType: input.budgetType,
                        actionType: input.actionType,
                        actionId: input.actionId,
                        status: input.status,
                        model: input.model,
                        version: input.version,
                        budgetYear: input.budgetYear,
                        showBonusesPlusHistory: input.showBonusesPlusHistory,
                        maxAmount: input.maxAmount,
                        isOverMaximum: input.isOverMaximum,
                        orderType: input.orderType,
                        siretPERF: input.siretPERF,
                        showDealerHistory: input.showDealerHistory
                      };
              }), val.trim().slice(0, 6));
      }),
    value: form.input.orderId,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    label: Messages_Bonuses_List$DvmAdminFrontendFr.orderID
  };
  if (form.orderIdResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.orderIdResult);
  }
  var tmp$1;
  if (selectedRole === /* CompanyManager */2 || selectedRole === /* LldManager */5 || selectedRole === /* BigAccountManager */6 || User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1) {
    tmp$1 = null;
  } else {
    var match$17 = form.input.orderType;
    var tmp$2;
    switch (match$17) {
      case "CL" :
      case "PG" :
      case "RC" :
      case "RR" :
      case "VD" :
      case "VE" :
          tmp$2 = true;
          break;
      default:
        tmp$2 = false;
    }
    var tmp$3 = {
      label: Messages_Common$DvmAdminFrontendFr.budget,
      options: Belt_List.add(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$9[0], /* [] */0), (function (x) {
                  return {
                          label: {
                            TAG: /* String */3,
                            _0: x
                          },
                          value: x
                        };
                })), {
            label: {
              TAG: /* Message */0,
              _0: Messages_Common$DvmAdminFrontendFr.everything
            },
            value: ""
          }),
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.budgetType,
          onChange: Curry._1(form.updateBudgetType, (function (input, budgetType) {
                  return {
                          dr: input.dr,
                          dealerId: input.dealerId,
                          orderId: input.orderId,
                          budgetType: budgetType,
                          actionType: input.actionType,
                          actionId: input.actionId,
                          status: input.status,
                          model: input.model,
                          version: input.version,
                          budgetYear: input.budgetYear,
                          showBonusesPlusHistory: input.showBonusesPlusHistory,
                          maxAmount: input.maxAmount,
                          isOverMaximum: input.isOverMaximum,
                          orderType: input.orderType,
                          siretPERF: input.siretPERF,
                          showDealerHistory: input.showDealerHistory
                        };
                }))
        }
      },
      id: "budget",
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
      disabled: tmp$2
    };
    if (form.budgetTypeResult !== undefined) {
      tmp$3.validationResult = Caml_option.valFromOption(form.budgetTypeResult);
    }
    tmp$1 = React.createElement(Core.Grid, {
          children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$3),
          item: true
        });
  }
  var tmp$4;
  if (operation === /* View */1) {
    var tmp$5 = {
      label: Messages_Bonuses_List$DvmAdminFrontendFr.flashType,
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.actionType,
          onChange: Curry._1(form.updateActionType, (function (input, actionType) {
                  return {
                          dr: input.dr,
                          dealerId: input.dealerId,
                          orderId: input.orderId,
                          budgetType: input.budgetType,
                          actionType: actionType,
                          actionId: input.actionId,
                          status: input.status,
                          model: input.model,
                          version: input.version,
                          budgetYear: input.budgetYear,
                          showBonusesPlusHistory: input.showBonusesPlusHistory,
                          maxAmount: input.maxAmount,
                          isOverMaximum: input.isOverMaximum,
                          orderType: input.orderType,
                          siretPERF: input.siretPERF,
                          showDealerHistory: input.showDealerHistory
                        };
                }))
        }
      },
      options: {
        TAG: /* Unlabeled */0,
        _0: Bonuses_Search_Filter_Utils$DvmAdminFrontendFr.handleActionTypesList(form, match$10[0], actionTypesNonFleet, selectedRole)
      },
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
    };
    if (form.actionTypeResult !== undefined) {
      tmp$5.validationResult = Caml_option.valFromOption(form.actionTypeResult);
    }
    tmp$4 = React.createElement(Core.Grid, {
          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$5),
          item: true
        });
  } else {
    tmp$4 = null;
  }
  var tmp$6 = {
    label: Messages_Bonuses_List$DvmAdminFrontendFr.flashID,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.actionId,
        onChange: Curry._1(form.updateActionId, (function (input, actionId) {
                return {
                        dr: input.dr,
                        dealerId: input.dealerId,
                        orderId: input.orderId,
                        budgetType: input.budgetType,
                        actionType: input.actionType,
                        actionId: actionId,
                        status: input.status,
                        model: input.model,
                        version: input.version,
                        budgetYear: input.budgetYear,
                        showBonusesPlusHistory: input.showBonusesPlusHistory,
                        maxAmount: input.maxAmount,
                        isOverMaximum: input.isOverMaximum,
                        orderType: input.orderType,
                        siretPERF: input.siretPERF,
                        showDealerHistory: input.showDealerHistory
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: Bonuses_Search_Filter_Utils$DvmAdminFrontendFr.handleActionsDropdownList(form, actionsFleet, actionsNonFleet)
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.actionIdResult !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(form.actionIdResult);
  }
  var tmp$7 = {
    label: Messages_Common$DvmAdminFrontendFr.year,
    value: form.input.budgetYear,
    onChange: Curry._1(form.updateBudgetYear, (function (input, budgetYear) {
            return {
                    dr: input.dr,
                    dealerId: input.dealerId,
                    orderId: input.orderId,
                    budgetType: input.budgetType,
                    actionType: input.actionType,
                    actionId: input.actionId,
                    status: input.status,
                    model: input.model,
                    version: input.version,
                    budgetYear: budgetYear,
                    showBonusesPlusHistory: input.showBonusesPlusHistory,
                    maxAmount: input.maxAmount,
                    isOverMaximum: input.isOverMaximum,
                    orderType: input.orderType,
                    siretPERF: input.siretPERF,
                    showDealerHistory: input.showDealerHistory
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    variant: /* YEAR */0
  };
  if (form.budgetYearResult !== undefined) {
    tmp$7.validationResult = Caml_option.valFromOption(form.budgetYearResult);
  }
  var tmp$8 = {
    label: Belt_List.length(form.input.status) > 0 ? Messages_Common$DvmAdminFrontendFr.state : Messages_Orders_Search$DvmAdminFrontendFr.stateWithoutAnulee,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: form.input.status,
        onChange: Curry._1(form.updateStatus, (function (input, status) {
                return {
                        dr: input.dr,
                        dealerId: input.dealerId,
                        orderId: input.orderId,
                        budgetType: input.budgetType,
                        actionType: input.actionType,
                        actionId: input.actionId,
                        status: status,
                        model: input.model,
                        version: input.version,
                        budgetYear: input.budgetYear,
                        showBonusesPlusHistory: input.showBonusesPlusHistory,
                        maxAmount: input.maxAmount,
                        isOverMaximum: input.isOverMaximum,
                        orderType: input.orderType,
                        siretPERF: input.siretPERF,
                        showDealerHistory: input.showDealerHistory
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: Belt_List.map(Belt_List.sort(Belt_List.keep(match$14[0], (function (x) {
                      if (operation === /* View */1) {
                        return true;
                      } else if (x.paramOne !== /* BLOCKED */0 && x.paramOne !== /* CALCULABLE */1 && x.paramOne !== /* NOBONUS */3 && x.paramOne !== /* PAID */4 && x.paramOne !== /* CANCELED */2) {
                        return x.paramOne !== /* PREINVOICED */5;
                      } else {
                        return false;
                      }
                    })), (function (a, b) {
                  return Belt_Option.getWithDefault(Belt_Int.fromString(a.paramTwo), 0) - Belt_Option.getWithDefault(Belt_Int.fromString(b.paramTwo), 0) | 0;
                })), (function (x) {
              return {
                      label: {
                        TAG: /* Message */0,
                        _0: App_Types_Status$DvmAdminFrontendFr.toMessage(x.paramOne)
                      },
                      value: App_Types_Status$DvmAdminFrontendFr.toString(x.paramOne)
                    };
            }))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputLong,
    limitTags: 4,
    smallChips: true
  };
  if (form.statusResult !== undefined) {
    tmp$8.validationResult = Caml_option.valFromOption(form.statusResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  submit(undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, operation === /* Manage */0 ? null : React.createElement(Core.Grid, {
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.orderType,
                              value: {
                                TAG: /* Single */0,
                                _0: {
                                  value: form.input.orderType,
                                  onChange: Curry._1(form.updateOrderType, (function (input, orderType) {
                                          return {
                                                  dr: input.dr,
                                                  dealerId: input.dealerId,
                                                  orderId: input.orderId,
                                                  budgetType: input.budgetType,
                                                  actionType: input.actionType,
                                                  actionId: input.actionId,
                                                  status: input.status,
                                                  model: input.model,
                                                  version: input.version,
                                                  budgetYear: input.budgetYear,
                                                  showBonusesPlusHistory: input.showBonusesPlusHistory,
                                                  maxAmount: input.maxAmount,
                                                  isOverMaximum: input.isOverMaximum,
                                                  orderType: orderType,
                                                  siretPERF: input.siretPERF,
                                                  showDealerHistory: input.showDealerHistory
                                                };
                                        }))
                                }
                              },
                              options: {
                                TAG: /* Unlabeled */0,
                                _0: EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? ({
                                      hd: "SO",
                                      tl: {
                                        hd: "CL",
                                        tl: {
                                          hd: "VD",
                                          tl: /* [] */0
                                        }
                                      }
                                    }) : (
                                    User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? ({
                                          hd: "SO",
                                          tl: {
                                            hd: "CL",
                                            tl: {
                                              hd: "VD",
                                              tl: {
                                                hd: "VE",
                                                tl: {
                                                  hd: "RR",
                                                  tl: {
                                                    hd: "RC",
                                                    tl: {
                                                      hd: "PG",
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }) : ({
                                          hd: "CL",
                                          tl: {
                                            hd: "VD",
                                            tl: {
                                              hd: "VE",
                                              tl: {
                                                hd: "RR",
                                                tl: {
                                                  hd: "RC",
                                                  tl: {
                                                    hd: "PG",
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        })
                                  )
                              },
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(drAndDealers),
                            children: React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  style: {
                                    width: "fit-content"
                                  }
                                }, React.createElement(Core.Grid, {
                                      children: selectedRole === /* CompanyManager */2 ? React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                              label: Messages_Common$DvmAdminFrontendFr.dealerID,
                                              value: {
                                                TAG: /* Multiple */1,
                                                _0: {
                                                  value: form.input.dealerId,
                                                  onChange: (function (val) {
                                                      Curry._1(setDealerSearchInput, (function (param) {
                                                              return "";
                                                            }));
                                                      Curry._2(form.updateDealerId, (function (input, dealerId) {
                                                              return {
                                                                      dr: input.dr,
                                                                      dealerId: dealerId,
                                                                      orderId: input.orderId,
                                                                      budgetType: input.budgetType,
                                                                      actionType: input.actionType,
                                                                      actionId: input.actionId,
                                                                      status: input.status,
                                                                      model: input.model,
                                                                      version: input.version,
                                                                      budgetYear: input.budgetYear,
                                                                      showBonusesPlusHistory: input.showBonusesPlusHistory,
                                                                      maxAmount: input.maxAmount,
                                                                      isOverMaximum: input.isOverMaximum,
                                                                      orderType: input.orderType,
                                                                      siretPERF: input.siretPERF,
                                                                      showDealerHistory: input.showDealerHistory
                                                                    };
                                                            }), val);
                                                    })
                                                }
                                              },
                                              options: {
                                                TAG: /* Unlabeled */0,
                                                _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(drAndDealers, /* [] */0, (function (x) {
                                                        return Belt_List.sort(Belt_List.concatMany(Belt_List.toArray(Belt_List.map(x, (function (x) {
                                                                                  return x.child;
                                                                                })))), Caml.string_compare);
                                                      }))
                                              },
                                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                                              onSearch: (function (val) {
                                                  Curry._1(setDealerSearchInput, (function (param) {
                                                          return val.trim().slice(0, 6);
                                                        }));
                                                }),
                                              inputValue: dealerSearchInput
                                            }) : React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                              labelParent: Messages_Common$DvmAdminFrontendFr.territory,
                                              labelChild: Messages_Common$DvmAdminFrontendFr.dealerID,
                                              valueParent: {
                                                TAG: /* Multiple */1,
                                                _0: {
                                                  value: form.input.dr,
                                                  onChange: Curry._1(form.updateDr, (function (input, dr) {
                                                          return {
                                                                  dr: dr,
                                                                  dealerId: input.dealerId,
                                                                  orderId: input.orderId,
                                                                  budgetType: input.budgetType,
                                                                  actionType: input.actionType,
                                                                  actionId: input.actionId,
                                                                  status: input.status,
                                                                  model: input.model,
                                                                  version: input.version,
                                                                  budgetYear: input.budgetYear,
                                                                  showBonusesPlusHistory: input.showBonusesPlusHistory,
                                                                  maxAmount: input.maxAmount,
                                                                  isOverMaximum: input.isOverMaximum,
                                                                  orderType: input.orderType,
                                                                  siretPERF: input.siretPERF,
                                                                  showDealerHistory: input.showDealerHistory
                                                                };
                                                        }))
                                                }
                                              },
                                              valueChild: {
                                                TAG: /* Multiple */1,
                                                _0: {
                                                  value: form.input.dealerId,
                                                  onChange: (function (val) {
                                                      Curry._1(setDealerSearchInput, (function (param) {
                                                              return "";
                                                            }));
                                                      Curry._2(form.updateDealerId, (function (input, dealerId) {
                                                              return {
                                                                      dr: input.dr,
                                                                      dealerId: dealerId,
                                                                      orderId: input.orderId,
                                                                      budgetType: input.budgetType,
                                                                      actionType: input.actionType,
                                                                      actionId: input.actionId,
                                                                      status: input.status,
                                                                      model: input.model,
                                                                      version: input.version,
                                                                      budgetYear: input.budgetYear,
                                                                      showBonusesPlusHistory: input.showBonusesPlusHistory,
                                                                      maxAmount: input.maxAmount,
                                                                      isOverMaximum: input.isOverMaximum,
                                                                      orderType: input.orderType,
                                                                      siretPERF: input.siretPERF,
                                                                      showDealerHistory: input.showDealerHistory
                                                                    };
                                                            }), val);
                                                    })
                                                }
                                              },
                                              options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(drAndDealers, /* [] */0),
                                              classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                                              classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputLong,
                                              limitTagsParent: 2,
                                              limitTagsChild: 2,
                                              selectParentIfOnlyOne: true,
                                              selectChildIfOnlyOne: true,
                                              onSearchChild: (function (val) {
                                                  Curry._1(setDealerSearchInput, (function (param) {
                                                          return val.trim().slice(0, 6);
                                                        }));
                                                }),
                                              inputValueChild: dealerSearchInput
                                            }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.FormControlLabel, {
                                            classes: {
                                              label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                            },
                                            control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                                  checked: form.input.showDealerHistory,
                                                  onClick: (function ($$event) {
                                                      Curry._2(form.updateShowDealerHistory, (function (input, showDealerHistory) {
                                                              return {
                                                                      dr: input.dr,
                                                                      dealerId: input.dealerId,
                                                                      orderId: input.orderId,
                                                                      budgetType: input.budgetType,
                                                                      actionType: input.actionType,
                                                                      actionId: input.actionId,
                                                                      status: input.status,
                                                                      model: input.model,
                                                                      version: input.version,
                                                                      budgetYear: input.budgetYear,
                                                                      showBonusesPlusHistory: input.showBonusesPlusHistory,
                                                                      maxAmount: input.maxAmount,
                                                                      isOverMaximum: input.isOverMaximum,
                                                                      orderType: input.orderType,
                                                                      siretPERF: input.siretPERF,
                                                                      showDealerHistory: showDealerHistory
                                                                    };
                                                            }), $$event.target.checked);
                                                    })
                                                }),
                                            label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.showDealerHistory)
                                          }),
                                      item: true
                                    }))
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), tmp$1, tmp$4, React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(actionsFleet) || App_Types_Result$DvmAdminFrontendFr.isPending(actionsNonFleet),
                            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$6)
                          }),
                      item: true
                    }), operation === /* Manage */0 ? React.createElement(Core.Grid, {
                        children: React.createElement(App_CompanyFormInput$DvmAdminFrontendFr.make, {
                              onChange: (function (siretPERF) {
                                  Curry._2(form.updateSiretPERF, (function (input, siretPERF) {
                                          return {
                                                  dr: input.dr,
                                                  dealerId: input.dealerId,
                                                  orderId: input.orderId,
                                                  budgetType: input.budgetType,
                                                  actionType: input.actionType,
                                                  actionId: input.actionId,
                                                  status: input.status,
                                                  model: input.model,
                                                  version: input.version,
                                                  budgetYear: input.budgetYear,
                                                  showBonusesPlusHistory: input.showBonusesPlusHistory,
                                                  maxAmount: input.maxAmount,
                                                  isOverMaximum: input.isOverMaximum,
                                                  orderType: input.orderType,
                                                  siretPERF: siretPERF,
                                                  showDealerHistory: input.showDealerHistory
                                                };
                                        }), siretPERF.trim());
                                }),
                              value: form.input.siretPERF,
                              companies: match$7[0],
                              setCompanies: match$7[1],
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                              companyType: "siret",
                              label: Messages_Bonuses_List$DvmAdminFrontendFr.clientSiret
                            }),
                        item: true
                      }) : null, React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersionsFleet) || App_Types_Result$DvmAdminFrontendFr.isPending(modelVersionsNonFleet),
                            children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                  labelParent: Messages_Common$DvmAdminFrontendFr.model,
                                  labelChild: Messages_Common$DvmAdminFrontendFr.version,
                                  valueParent: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.model,
                                      onChange: Curry._1(form.updateModel, (function (input, model) {
                                              return {
                                                      dr: input.dr,
                                                      dealerId: input.dealerId,
                                                      orderId: input.orderId,
                                                      budgetType: input.budgetType,
                                                      actionType: input.actionType,
                                                      actionId: input.actionId,
                                                      status: input.status,
                                                      model: model,
                                                      version: input.version,
                                                      budgetYear: input.budgetYear,
                                                      showBonusesPlusHistory: input.showBonusesPlusHistory,
                                                      maxAmount: input.maxAmount,
                                                      isOverMaximum: input.isOverMaximum,
                                                      orderType: input.orderType,
                                                      siretPERF: input.siretPERF,
                                                      showDealerHistory: input.showDealerHistory
                                                    };
                                            }))
                                    }
                                  },
                                  valueChild: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.version,
                                      onChange: Curry._1(form.updateVersion, (function (input, version) {
                                              return {
                                                      dr: input.dr,
                                                      dealerId: input.dealerId,
                                                      orderId: input.orderId,
                                                      budgetType: input.budgetType,
                                                      actionType: input.actionType,
                                                      actionId: input.actionId,
                                                      status: input.status,
                                                      model: input.model,
                                                      version: version,
                                                      budgetYear: input.budgetYear,
                                                      showBonusesPlusHistory: input.showBonusesPlusHistory,
                                                      maxAmount: input.maxAmount,
                                                      isOverMaximum: input.isOverMaximum,
                                                      orderType: input.orderType,
                                                      siretPERF: input.siretPERF,
                                                      showDealerHistory: input.showDealerHistory
                                                    };
                                            }))
                                    }
                                  },
                                  options: Bonuses_Search_Filter_Utils$DvmAdminFrontendFr.handleModelVersionsList(form, modelVersionsFleet, modelVersionsNonFleet),
                                  classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                                  classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      style: {
                        width: "fit-content"
                      }
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$7),
                          item: true
                        }), operation === /* View */1 ? React.createElement(Core.Grid, {
                            children: React.createElement(Core.FormControlLabel, {
                                  classes: {
                                    label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                  },
                                  control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                        checked: form.input.showBonusesPlusHistory,
                                        onClick: (function ($$event) {
                                            Curry._2(form.updateShowBonusesPlusHistory, (function (input, showBonusesPlusHistory) {
                                                    return {
                                                            dr: input.dr,
                                                            dealerId: input.dealerId,
                                                            orderId: input.orderId,
                                                            budgetType: input.budgetType,
                                                            actionType: input.actionType,
                                                            actionId: input.actionId,
                                                            status: input.status,
                                                            model: input.model,
                                                            version: input.version,
                                                            budgetYear: input.budgetYear,
                                                            showBonusesPlusHistory: showBonusesPlusHistory,
                                                            maxAmount: input.maxAmount,
                                                            isOverMaximum: input.isOverMaximum,
                                                            orderType: input.orderType,
                                                            siretPERF: input.siretPERF,
                                                            showDealerHistory: input.showDealerHistory
                                                          };
                                                  }), $$event.target.checked);
                                          })
                                      }),
                                  label: intl.formatMessage(Messages_Bonuses_List$DvmAdminFrontendFr.showBonusesPlusHistory)
                                }),
                            item: true,
                            style: {
                              whiteSpace: "nowrap",
                              width: "1px"
                            }
                          }) : null), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$8),
                      item: true
                    }), operation ? React.createElement(Core.Grid, {
                        children: React.createElement(Bonuses_Search_Modal_Wrapper$DvmAdminFrontendFr.make, {
                              form: form,
                              formModal: formModal,
                              formAdditional: formAdditional,
                              formAdditionalSecond: formAdditionalSecond,
                              formModalVO: formModalVO,
                              modal: match$1[0],
                              setModal: setModal,
                              submit: submit,
                              modalPending: modalPending,
                              setModalPending: match$2[1]
                            }),
                        item: true
                      }) : React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            direction: "column",
                            item: true,
                            style: {
                              width: "fit-content"
                            }
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                      onChange: Curry._1(form.updateMaxAmount, (function (input, maxAmount) {
                                              return {
                                                      dr: input.dr,
                                                      dealerId: input.dealerId,
                                                      orderId: input.orderId,
                                                      budgetType: input.budgetType,
                                                      actionType: input.actionType,
                                                      actionId: input.actionId,
                                                      status: input.status,
                                                      model: input.model,
                                                      version: input.version,
                                                      budgetYear: input.budgetYear,
                                                      showBonusesPlusHistory: input.showBonusesPlusHistory,
                                                      maxAmount: maxAmount,
                                                      isOverMaximum: input.isOverMaximum,
                                                      orderType: input.orderType,
                                                      siretPERF: input.siretPERF,
                                                      showDealerHistory: input.showDealerHistory
                                                    };
                                            })),
                                      value: form.input.maxAmount,
                                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                                      label: Messages_Bonuses_Manage$DvmAdminFrontendFr.authorizedAmount,
                                      InputProps: {
                                        startAdornment: React.createElement(Core.InputAdornment, {
                                              children: "€",
                                              position: "start"
                                            })
                                      },
                                      inputType: "number"
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(Core.FormControlLabel, {
                                      classes: {
                                        label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                      },
                                      control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                            checked: form.input.isOverMaximum,
                                            onClick: (function ($$event) {
                                                Curry._2(form.updateIsOverMaximum, (function (input, isOverMaximum) {
                                                        return {
                                                                dr: input.dr,
                                                                dealerId: input.dealerId,
                                                                orderId: input.orderId,
                                                                budgetType: input.budgetType,
                                                                actionType: input.actionType,
                                                                actionId: input.actionId,
                                                                status: input.status,
                                                                model: input.model,
                                                                version: input.version,
                                                                budgetYear: input.budgetYear,
                                                                showBonusesPlusHistory: input.showBonusesPlusHistory,
                                                                maxAmount: input.maxAmount,
                                                                isOverMaximum: isOverMaximum,
                                                                orderType: input.orderType,
                                                                siretPERF: input.siretPERF,
                                                                showDealerHistory: input.showDealerHistory
                                                              };
                                                      }), $$event.target.checked);
                                              })
                                          }),
                                      label: intl.formatMessage(Messages_Bonuses_Manage$DvmAdminFrontendFr.searchExceededAmount)
                                    }),
                                item: true,
                                style: {
                                  whiteSpace: "nowrap",
                                  width: "1px"
                                }
                              })), React.createElement(Core.Grid, {
                            children: React.createElement(Core.Button, {
                                  onClick: (function (param) {
                                      Curry._1(dispatch, {
                                            TAG: /* StoreBonusesPERFFilter */19,
                                            _0: undefined
                                          });
                                      Curry._1(form.reset, undefined);
                                      Curry._1(form.submit, undefined);
                                    }),
                                  children: null,
                                  className: modalButton,
                                  type: "button"
                                }, React.createElement(Assets$DvmAdminFrontendFr.DesyreFilterIcon.make, {}), React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                      size: /* Regular */2,
                                      type_: /* Cross */42,
                                      iconClassName: iconSmall,
                                      color: Theme_Colors$DvmAdminFrontendFr.primaryBrown
                                    })),
                            item: true
                          })), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: modalPending,
                            children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                                  label: "OK",
                                  type_: "submit"
                                })
                          }),
                      item: true
                    })));
}

var Form;

var FormModal;

var FormModalAdditional;

var FormModalVO;

var Api;

var Types;

var InputStyles;

var Utils;

var make = Bonuses_Search_Filter;

export {
  Form ,
  FormModal ,
  FormModalAdditional ,
  FormModalVO ,
  Api ,
  Types ,
  InputStyles ,
  Utils ,
  modalButton ,
  icon ,
  iconSmall ,
  make ,
}
/* modalButton Not a pure module */
