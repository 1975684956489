// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.bs.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Order_Search_Modal$DvmAdminFrontendFr from "./modal/Order_Search_Modal.bs.js";
import * as Order_Search_Modal_Utils$DvmAdminFrontendFr from "./modal/Order_Search_Modal_Utils.bs.js";

var modalButton = Css.css(Object.assign({}, {
          color: Theme_Colors$DvmAdminFrontendFr.black,
          height: "48px",
          width: "48px"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayMain
          }
        }));

var icon = Css.css({
      fontWeight: "bold"
    });

function Order_Search_Modal_Wrapper(Props) {
  var form = Props.form;
  var formModal = Props.formModal;
  var formAdditional = Props.formAdditional;
  var formModalVO = Props.formModalVO;
  var modal = Props.modal;
  var setModal = Props.setModal;
  var submit = Props.submit;
  var modalPending = Props.modalPending;
  var setModalPending = Props.setModalPending;
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return 0;
      });
  var setModalNumber = match[1];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFleetManagers = match$3[1];
  var fleetManagers = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setLldManagers = match$4[1];
  var lldManagers = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDealerGroups = match$5[1];
  var dealerGroups = match$5[0];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSalesmanNames = match$6[1];
  var salesmanNames = match$6[0];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAgentCodes = match$7[1];
  var agentCodes = match$7[0];
  var fetchDropdowns = function (dealerIds) {
    App_Api$DvmAdminFrontendFr.fetchDealerGroups(userManager, setDealerGroups, alert, selectedRole, dealerIds);
    App_Api$DvmAdminFrontendFr.fetchSalesmanNames(userManager, setSalesmanNames, alert, selectedRole, dealerIds);
    App_Api$DvmAdminFrontendFr.fetchFleetManagers(userManager, setFleetManagers, alert, selectedRole, dealerIds);
    App_Api$DvmAdminFrontendFr.fetchLldManagers(userManager, setLldManagers, alert, selectedRole, dealerIds);
    App_Api$DvmAdminFrontendFr.fetchDealersAndAgents(userManager, setAgentCodes, selectedRole, dealerIds);
  };
  React.useEffect((function () {
          Curry._1(setModalNumber, (function (param) {
                  return Order_Search_Modal_Utils$DvmAdminFrontendFr.handleModalNumber(formModal, formAdditional, formModalVO);
                }));
        }), [form]);
  var debounce = ReactDebounce.useDebounced(1000, fetchDropdowns);
  React.useEffect((function () {
          fetchDropdowns(form.input.dealerId);
        }), []);
  React.useEffect((function () {
          Curry._1(debounce, form.input.dealerId);
        }), [form.input.dealerId]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(fleetManagers, undefined, (function (x) {
                  if (Belt_List.has(x, formAdditional.input.keyAccountManagerID, (function (a, b) {
                            return a === b;
                          }))) {
                    return ;
                  } else {
                    return Curry._2(formAdditional.updateKeyAccountManagerID, (function (input, keyAccountManagerID) {
                                  var newrecord = Caml_obj.obj_dup(input);
                                  newrecord.keyAccountManagerID = keyAccountManagerID;
                                  return newrecord;
                                }), "");
                  }
                }));
        }), [fleetManagers]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(lldManagers, undefined, (function (x) {
                  if (Belt_List.has(x, formAdditional.input.sectorManagerID, (function (a, b) {
                            return a === b;
                          }))) {
                    return ;
                  } else {
                    return Curry._2(formAdditional.updateSectorManagerID, (function (input, sectorManagerID) {
                                  var newrecord = Caml_obj.obj_dup(input);
                                  newrecord.sectorManagerID = sectorManagerID;
                                  return newrecord;
                                }), "");
                  }
                }));
        }), [lldManagers]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(dealerGroups, undefined, (function (x) {
                  if (Belt_List.has(x, formAdditional.input.dealerGroup, (function (a, b) {
                            return a === b;
                          }))) {
                    return ;
                  } else {
                    return Curry._2(formAdditional.updateDealerGroup, (function (input, dealerGroup) {
                                  var newrecord = Caml_obj.obj_dup(input);
                                  newrecord.dealerGroup = dealerGroup;
                                  return newrecord;
                                }), "");
                  }
                }));
        }), [dealerGroups]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(salesmanNames, undefined, (function (x) {
                  if (Belt_List.has(x, formModal.input.salesmanName, (function (a, b) {
                            return a === b;
                          }))) {
                    return ;
                  } else {
                    return Curry._2(formModal.updateSalesmanName, (function (input, salesmanName) {
                                  var newrecord = Caml_obj.obj_dup(input);
                                  newrecord.salesmanName = salesmanName;
                                  return newrecord;
                                }), "");
                  }
                }));
        }), [salesmanNames]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(agentCodes, undefined, (function (x) {
                  if (Belt_List.has(Belt_List.concatMany(Belt_List.toArray(Belt_List.map(x, (function (x) {
                                        return x.child;
                                      })))), formModal.input.agentCode, (function (a, b) {
                            return a.paramOne === b;
                          }))) {
                    return ;
                  } else {
                    return Curry._2(formModal.updateAgentCode, (function (input, agentCode) {
                                  var newrecord = Caml_obj.obj_dup(input);
                                  newrecord.agentCode = agentCode;
                                  return newrecord;
                                }), "");
                  }
                }));
        }), [agentCodes]);
  React.useEffect((function () {
          Curry._1(setModalPending, (function (param) {
                  if (App_Types_Result$DvmAdminFrontendFr.isPending(fleetManagers) || App_Types_Result$DvmAdminFrontendFr.isPending(lldManagers) || App_Types_Result$DvmAdminFrontendFr.isPending(dealerGroups)) {
                    return true;
                  } else {
                    return App_Types_Result$DvmAdminFrontendFr.isPending(salesmanNames);
                  }
                }));
        }), [
        fleetManagers,
        lldManagers,
        dealerGroups,
        salesmanNames
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: modalPending,
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return true;
                                  }));
                          }),
                        children: React.createElement(Core.Badge, {
                              anchorOrigin: {
                                horizontal: "right",
                                vertical: "bottom"
                              },
                              badgeContent: match[0],
                              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                    size: /* Large */3,
                                    type_: /* Filter */63,
                                    iconClassName: icon
                                  }),
                              color: "primary"
                            }),
                        className: modalButton,
                        type: "button"
                      })
                }), modal ? React.createElement(Order_Search_Modal$DvmAdminFrontendFr.make, {
                    onClose: (function (param) {
                        Curry._1(setModal, (function (param) {
                                return false;
                              }));
                      }),
                    submit: submit,
                    orderType: form.input.orderType,
                    form: formModal,
                    formAdditional: formAdditional,
                    formModalVO: formModalVO,
                    fleetManagers: fleetManagers,
                    lldManagers: lldManagers,
                    dealerGroups: dealerGroups,
                    salesmanNames: salesmanNames,
                    agentCodes: agentCodes,
                    dealerIds: form.input.dealerId
                  }) : null);
}

var make = Order_Search_Modal_Wrapper;

export {
  modalButton ,
  icon ,
  make ,
}
/* modalButton Not a pure module */
