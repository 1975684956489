// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_Status.bs.js";
import * as App_Types_VN_VO_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_VN_VO_Status.bs.js";

var Fields_dr = [
  "OrderDR",
  "Territoire"
];

var Fields_dealerID = [
  "OrderDealerID",
  "Compte_Affaire"
];

var Fields_newCarRegistrationID = [
  "OrderRegistrationID",
  "Immatriculation_VN"
];

var Fields_customerOrderNumber = [
  "OrderCustomerOrderNumber",
  "Numero_de_commande"
];

var Fields_orderNumberCar = [
  "OrderCarOrderNumber",
  "Numero_d_ordre"
];

var Fields_budgetType = [
  "OrderBudgetType",
  "Type_de_Budget"
];

var Fields_budgetStatus = [
  "ResultBudgetStatus",
  "Statut"
];

var Fields_siren = [
  "SIREN",
  "SIREN_Client"
];

var Fields_vin = [
  "OrderVIN",
  "VIN"
];

var Fields_orderDate = [
  "OrderOrderDate",
  "Date_de_commande"
];

var Fields_deliveryDateBoth = [
  "ResultDeliveryDate",
  "Date_Livraison_Client"
];

var Fields_modelID = [
  "OrderModelID",
  "Modèle_SC"
];

var Fields_versionID = [
  "OrderVersionID",
  "Version_SC"
];

var Fields_discount = [
  "OrderCompanyDiscount",
  "Discount"
];

var Fields_registrationDate = [
  "OrderRegistrationDate",
  "Date_Immatriculation_VN"
];

var Fields_customerInvoice = [
  "OrderCustomerInvoiceDate",
  "Date_Facture_Client"
];

var Fields_delivered = [
  "ResultDelivered",
  "Livree"
];

var Fields_customerName = [
  "orderCustomerName",
  "Raison_sociale_client"
];

var Fields_salesmanName = [
  "OrderSalesmanName",
  "Nom_de_Vendeur"
];

var Fields_agentCode = [
  "OrderAgentCode",
  "Code_de_l_agent"
];

var Fields_categoryLabel = [
  "CategoryName",
  "Category"
];

var Fields_archived = [
  "ResultOrderArchived",
  "Archivée"
];

var Fields_renaultInvoiceDate = [
  "OrderRenaultInvoiceDate",
  "Date_Facture_Renault"
];

var Fields_dealerName = [
  "DealerName",
  "Raison_sociale_de_l_affaire"
];

var Fields_dealerGroup = [
  "DealerGroup",
  "Groupe_Affaire"
];

var Fields_keyAccountManagerID = [
  "DealerKeyAccountManager",
  "Nom_ME_Local"
];

var Fields_sectorManagerID = [
  "ContractManagerFinalCustomer",
  "Nom_Intervenant"
];

var Fields_sirenClientFinalLLD = [
  "OrderSIRENFinalCustomer",
  "SIRET_Client_Final_LLD"
];

var Fields_sirenClientFinalLLDName = [
  "SIRENNameFinalCustomer",
  "Raison_Sociale_Client_Final_LLD"
];

var Fields_loueurID = [
  "ContractIDLeaser",
  "ID_Loueur"
];

var Fields_loueurName = [
  "ContractLabelLeaser",
  "Libelle_ID_Loueur"
];

var Fields_contractID = [
  "ContractIDFinalCustomer",
  "Code_Protocole_Client_Final"
];

var Fields_contractName = [
  "ContractLabelFinalCustomer",
  "Libelle_du_contrat"
];

var Fields_mainContractSIREN = [
  "ContractMainSIRENFinalCustomer",
  "Main_Siren_of_the_contract"
];

var Fields_codeAPE = [
  "SIRETAPECode",
  "CodeAPE"
];

var Fields_orderType = [
  "OrderOrderType",
  "Type_de_Commande"
];

var Fields_brandUsedCar = [
  "OrderUCBrand",
  "Marque_VO"
];

var Fields_modelUsedCar = [
  "OrderUCModel",
  "Modele_VO"
];

var Fields_vinUsedCar = [
  "OrderUCVIN",
  "VIN_VO"
];

var Fields_usedCarPoliceNumber = [
  "UCPoliceNumber",
  "Numero_de_police"
];

var Fields_usedCarLastRegistrationDate = [
  "OrderUCLastRegistrationDate",
  "Date_derniere_Immat_VO"
];

var Fields_usedCarFirstReleaseDate = [
  "OrderUCFirstReleaseDate",
  "Date_premiere_Immat_VO"
];

var Fields_usedCarRegistrationID = [
  "OrderUCRegistrationID",
  "Immat_VO"
];

var Fields_orderStatus = [
  "ResultOrderStatus",
  "StatutVN"
];

var Fields_usedCarStatus = [
  "ResultUCStatus",
  "StatutVO"
];

var Fields_phase = [
  "AOCPhase",
  "Phase"
];

var Fields_vehicleType = [
  "AOCVehicleType",
  "Type_de_vehicule"
];

var Fields_engineType = [
  "AOCEngineType",
  "Energie"
];

var Fields_brand = [
  "AOCBrand",
  "Marque"
];

var Fields_firstName = [
  "OrderCustomerFirstName",
  "Prenom_Client"
];

var Fields_entryDateParc = [
  "orderParcStartDate",
  "Date_Entree_Parc"
];

var Fields_exitDateParc = [
  "orderParcEndDate",
  "Date_Sortie_Parc"
];

var Fields_showHistoryDealer = [
  "resultActiveDealerLocal",
  "Affaire_active"
];

var Fields = {
  dr: Fields_dr,
  dealerID: Fields_dealerID,
  newCarRegistrationID: Fields_newCarRegistrationID,
  customerOrderNumber: Fields_customerOrderNumber,
  orderNumberCar: Fields_orderNumberCar,
  budgetType: Fields_budgetType,
  budgetStatus: Fields_budgetStatus,
  siren: Fields_siren,
  vin: Fields_vin,
  orderDate: Fields_orderDate,
  deliveryDateBoth: Fields_deliveryDateBoth,
  modelID: Fields_modelID,
  versionID: Fields_versionID,
  discount: Fields_discount,
  registrationDate: Fields_registrationDate,
  customerInvoice: Fields_customerInvoice,
  delivered: Fields_delivered,
  customerName: Fields_customerName,
  salesmanName: Fields_salesmanName,
  agentCode: Fields_agentCode,
  categoryLabel: Fields_categoryLabel,
  archived: Fields_archived,
  renaultInvoiceDate: Fields_renaultInvoiceDate,
  dealerName: Fields_dealerName,
  dealerGroup: Fields_dealerGroup,
  keyAccountManagerID: Fields_keyAccountManagerID,
  sectorManagerID: Fields_sectorManagerID,
  sirenClientFinalLLD: Fields_sirenClientFinalLLD,
  sirenClientFinalLLDName: Fields_sirenClientFinalLLDName,
  loueurID: Fields_loueurID,
  loueurName: Fields_loueurName,
  contractID: Fields_contractID,
  contractName: Fields_contractName,
  mainContractSIREN: Fields_mainContractSIREN,
  codeAPE: Fields_codeAPE,
  orderType: Fields_orderType,
  brandUsedCar: Fields_brandUsedCar,
  modelUsedCar: Fields_modelUsedCar,
  vinUsedCar: Fields_vinUsedCar,
  usedCarPoliceNumber: Fields_usedCarPoliceNumber,
  usedCarLastRegistrationDate: Fields_usedCarLastRegistrationDate,
  usedCarFirstReleaseDate: Fields_usedCarFirstReleaseDate,
  usedCarRegistrationID: Fields_usedCarRegistrationID,
  orderStatus: Fields_orderStatus,
  usedCarStatus: Fields_usedCarStatus,
  phase: Fields_phase,
  vehicleType: Fields_vehicleType,
  engineType: Fields_engineType,
  brand: Fields_brand,
  firstName: Fields_firstName,
  entryDateParc: Fields_entryDateParc,
  exitDateParc: Fields_exitDateParc,
  showHistoryDealer: Fields_showHistoryDealer
};

function orderRow_encode(v) {
  return Js_dict.fromArray([
              [
                "orderDealerId",
                Decco.stringToJson(v.orderDealerId)
              ],
              [
                "orderCustomerOrderNumber",
                Decco.stringToJson(v.orderCustomerOrderNumber)
              ],
              [
                "orderCarOrderNumber",
                Decco.optionToJson(Decco.stringToJson, v.orderCarOrderNumber)
              ],
              [
                "orderOrderType",
                Decco.optionToJson(Decco.stringToJson, v.orderOrderType)
              ],
              [
                "orderBudgetType",
                Decco.optionToJson(Decco.stringToJson, v.orderBudgetType)
              ],
              [
                "resultBudgetStatus",
                Decco.optionToJson(App_Types_Status$DvmAdminFrontendFr.Decco.t_encode, v.resultBudgetStatus)
              ],
              [
                "siren",
                Decco.optionToJson(Decco.stringToJson, v.siren)
              ],
              [
                "orderCustomerName",
                Decco.optionToJson(Decco.stringToJson, v.orderCustomerName)
              ],
              [
                "orderVin",
                Decco.optionToJson(Decco.stringToJson, v.orderVin)
              ],
              [
                "orderOrderDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.orderOrderDate)
              ],
              [
                "resultDeliveryDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.resultDeliveryDate)
              ],
              [
                "orderModelId",
                Decco.optionToJson(Decco.stringToJson, v.orderModelId)
              ],
              [
                "orderVersionId",
                Decco.optionToJson(Decco.stringToJson, v.orderVersionId)
              ],
              [
                "orderCompanyDiscount",
                Decco.optionToJson(Decco.floatToJson, v.orderCompanyDiscount)
              ],
              [
                "resultOrderStatus",
                Decco.optionToJson(App_Types_VN_VO_Status$DvmAdminFrontendFr.Decco.t_encode, v.resultOrderStatus)
              ],
              [
                "resultUcStatus",
                Decco.optionToJson(App_Types_VN_VO_Status$DvmAdminFrontendFr.Decco.t_encode, v.resultUcStatus)
              ],
              [
                "orderParcStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.orderParcStartDate)
              ],
              [
                "orderParcEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.orderParcEndDate)
              ]
            ]);
}

function orderRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var orderDealerId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderDealerId"), null));
  if (orderDealerId.TAG === /* Ok */0) {
    var orderCustomerOrderNumber = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderCustomerOrderNumber"), null));
    if (orderCustomerOrderNumber.TAG === /* Ok */0) {
      var orderCarOrderNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderCarOrderNumber"), null));
      if (orderCarOrderNumber.TAG === /* Ok */0) {
        var orderOrderType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderOrderType"), null));
        if (orderOrderType.TAG === /* Ok */0) {
          var orderBudgetType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderBudgetType"), null));
          if (orderBudgetType.TAG === /* Ok */0) {
            var resultBudgetStatus = Decco.optionFromJson(App_Types_Status$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "resultBudgetStatus"), null));
            if (resultBudgetStatus.TAG === /* Ok */0) {
              var siren = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siren"), null));
              if (siren.TAG === /* Ok */0) {
                var orderCustomerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderCustomerName"), null));
                if (orderCustomerName.TAG === /* Ok */0) {
                  var orderVin = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderVin"), null));
                  if (orderVin.TAG === /* Ok */0) {
                    var orderOrderDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderOrderDate"), null));
                    if (orderOrderDate.TAG === /* Ok */0) {
                      var resultDeliveryDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "resultDeliveryDate"), null));
                      if (resultDeliveryDate.TAG === /* Ok */0) {
                        var orderModelId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderModelId"), null));
                        if (orderModelId.TAG === /* Ok */0) {
                          var orderVersionId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderVersionId"), null));
                          if (orderVersionId.TAG === /* Ok */0) {
                            var orderCompanyDiscount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderCompanyDiscount"), null));
                            if (orderCompanyDiscount.TAG === /* Ok */0) {
                              var resultOrderStatus = Decco.optionFromJson(App_Types_VN_VO_Status$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "resultOrderStatus"), null));
                              if (resultOrderStatus.TAG === /* Ok */0) {
                                var resultUcStatus = Decco.optionFromJson(App_Types_VN_VO_Status$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "resultUcStatus"), null));
                                if (resultUcStatus.TAG === /* Ok */0) {
                                  var orderParcStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderParcStartDate"), null));
                                  if (orderParcStartDate.TAG === /* Ok */0) {
                                    var orderParcEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderParcEndDate"), null));
                                    if (orderParcEndDate.TAG === /* Ok */0) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: {
                                                orderDealerId: orderDealerId._0,
                                                orderCustomerOrderNumber: orderCustomerOrderNumber._0,
                                                orderCarOrderNumber: orderCarOrderNumber._0,
                                                orderOrderType: orderOrderType._0,
                                                orderBudgetType: orderBudgetType._0,
                                                resultBudgetStatus: resultBudgetStatus._0,
                                                siren: siren._0,
                                                orderCustomerName: orderCustomerName._0,
                                                orderVin: orderVin._0,
                                                orderOrderDate: orderOrderDate._0,
                                                resultDeliveryDate: resultDeliveryDate._0,
                                                orderModelId: orderModelId._0,
                                                orderVersionId: orderVersionId._0,
                                                orderCompanyDiscount: orderCompanyDiscount._0,
                                                resultOrderStatus: resultOrderStatus._0,
                                                resultUcStatus: resultUcStatus._0,
                                                orderParcStartDate: orderParcStartDate._0,
                                                orderParcEndDate: orderParcEndDate._0
                                              }
                                            };
                                    }
                                    var e = orderParcEndDate._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".orderParcEndDate" + e.path,
                                              message: e.message,
                                              value: e.value
                                            }
                                          };
                                  }
                                  var e$1 = orderParcStartDate._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".orderParcStartDate" + e$1.path,
                                            message: e$1.message,
                                            value: e$1.value
                                          }
                                        };
                                }
                                var e$2 = resultUcStatus._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".resultUcStatus" + e$2.path,
                                          message: e$2.message,
                                          value: e$2.value
                                        }
                                      };
                              }
                              var e$3 = resultOrderStatus._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".resultOrderStatus" + e$3.path,
                                        message: e$3.message,
                                        value: e$3.value
                                      }
                                    };
                            }
                            var e$4 = orderCompanyDiscount._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".orderCompanyDiscount" + e$4.path,
                                      message: e$4.message,
                                      value: e$4.value
                                    }
                                  };
                          }
                          var e$5 = orderVersionId._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".orderVersionId" + e$5.path,
                                    message: e$5.message,
                                    value: e$5.value
                                  }
                                };
                        }
                        var e$6 = orderModelId._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".orderModelId" + e$6.path,
                                  message: e$6.message,
                                  value: e$6.value
                                }
                              };
                      }
                      var e$7 = resultDeliveryDate._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".resultDeliveryDate" + e$7.path,
                                message: e$7.message,
                                value: e$7.value
                              }
                            };
                    }
                    var e$8 = orderOrderDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".orderOrderDate" + e$8.path,
                              message: e$8.message,
                              value: e$8.value
                            }
                          };
                  }
                  var e$9 = orderVin._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".orderVin" + e$9.path,
                            message: e$9.message,
                            value: e$9.value
                          }
                        };
                }
                var e$10 = orderCustomerName._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".orderCustomerName" + e$10.path,
                          message: e$10.message,
                          value: e$10.value
                        }
                      };
              }
              var e$11 = siren._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".siren" + e$11.path,
                        message: e$11.message,
                        value: e$11.value
                      }
                    };
            }
            var e$12 = resultBudgetStatus._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".resultBudgetStatus" + e$12.path,
                      message: e$12.message,
                      value: e$12.value
                    }
                  };
          }
          var e$13 = orderBudgetType._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".orderBudgetType" + e$13.path,
                    message: e$13.message,
                    value: e$13.value
                  }
                };
        }
        var e$14 = orderOrderType._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".orderOrderType" + e$14.path,
                  message: e$14.message,
                  value: e$14.value
                }
              };
      }
      var e$15 = orderCarOrderNumber._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".orderCarOrderNumber" + e$15.path,
                message: e$15.message,
                value: e$15.value
              }
            };
    }
    var e$16 = orderCustomerOrderNumber._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".orderCustomerOrderNumber" + e$16.path,
              message: e$16.message,
              value: e$16.value
            }
          };
  }
  var e$17 = orderDealerId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".orderDealerId" + e$17.path,
            message: e$17.message,
            value: e$17.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(orderRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(orderRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var OrderResult = {
  orderRow_encode: orderRow_encode,
  orderRow_decode: orderRow_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

function statusItem_encode(v) {
  return Js_dict.fromArray([
              [
                "paramOne",
                Curry._1(App_Types_Status$DvmAdminFrontendFr.Decco.t_encode, v.paramOne)
              ],
              [
                "paramTwo",
                Decco.stringToJson(v.paramTwo)
              ]
            ]);
}

function statusItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var paramOne = Curry._1(App_Types_Status$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "paramOne"), null));
  if (paramOne.TAG === /* Ok */0) {
    var paramTwo = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "paramTwo"), null));
    if (paramTwo.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                paramOne: paramOne._0,
                paramTwo: paramTwo._0
              }
            };
    }
    var e = paramTwo._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".paramTwo" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = paramOne._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".paramOne" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function statusList_encode(v) {
  return Decco.listToJson(statusItem_encode, v);
}

function statusList_decode(v) {
  return Decco.listFromJson(statusItem_decode, v);
}

export {
  Fields ,
  OrderResult ,
  statusItem_encode ,
  statusItem_decode ,
  statusList_encode ,
  statusList_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
