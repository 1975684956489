// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_FinancialCodes$DvmAdminFrontendFr from "../../../intl/messages/easypack/Messages_FinancialCodes.bs.js";

function isAgentZero(order) {
  return Belt_Option.mapWithDefault(order.detail.agentCode, false, (function (x) {
                return x === "000";
              }));
}

function isStringEmpty(someString) {
  return Belt_Option.mapWithDefault(someString, true, (function (x) {
                return x.length === 0;
              }));
}

function isFloatEmptyOrZero(someFloat) {
  return Belt_Option.mapWithDefault(someFloat, true, (function (x) {
                return x === 0.0;
              }));
}

function headerArray(order) {
  return Belt_List.toArray(Belt_List.keepMap({
                  hd: {
                    label: Messages_Common$DvmAdminFrontendFr.territory,
                    val: Belt_Option.getWithDefault(order.dr, "-")
                  },
                  tl: {
                    hd: {
                      label: Messages_Common$DvmAdminFrontendFr.dealerID,
                      val: Belt_Option.getWithDefault(order.dealerID, "-")
                    },
                    tl: {
                      hd: {
                        label: Messages_Orders_Detail$DvmAdminFrontendFr.dealerName,
                        val: Belt_Option.getWithDefault(order.locationCommonName, "-")
                      },
                      tl: {
                        hd: {
                          label: Messages_Orders_Detail$DvmAdminFrontendFr.dealerCompanyName,
                          val: Belt_Option.getWithDefault(order.dealerName, "-")
                        },
                        tl: {
                          hd: {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.customerOrderNum,
                            val: Belt_Option.getWithDefault(order.orderNumberCustomer, "-")
                          },
                          tl: {
                            hd: {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.carOrderNum,
                              val: Belt_Option.getWithDefault(order.orderNumberCar, "-")
                            },
                            tl: {
                              hd: {
                                label: Messages_Common$DvmAdminFrontendFr.orderType,
                                val: Belt_Option.mapWithDefault(order.orderType, "-", (function (x) {
                                        switch (x) {
                                          case /* SO */0 :
                                              return "SO - Societé";
                                          case /* CL */1 :
                                              return "CL - Particulier";
                                          case /* VE */2 :
                                              return "VE - PARC";
                                          case /* VD */3 :
                                              return "VD - Véhicule de démonstration agent";
                                          case /* RR */4 :
                                              return "RR - PARC";
                                          case /* RC */5 :
                                              return "RC - PARC";
                                          case /* PG */6 :
                                              return "PG";
                                          case /* NOTSET */7 :
                                              return "";
                                          
                                        }
                                      }))
                              },
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }, (function (x) {
                    return x;
                  })));
}

function orderDetail(order, intl, selectedRole) {
  return Belt_Option.mapWithDefault(order.orderType, [], (function (orderType) {
                if (orderType === 0) {
                  return [
                          Belt_List.toArray(Belt_List.keepMap({
                                    hd: {
                                      label: Messages_Common$DvmAdminFrontendFr.siret,
                                      val: Belt_Option.getWithDefault(order.detail.siret, "-")
                                    },
                                    tl: {
                                      hd: {
                                        label: Messages_Orders_Detail$DvmAdminFrontendFr.customerName,
                                        val: Belt_Option.getWithDefault(order.detail.customerName, "-")
                                      },
                                      tl: {
                                        hd: {
                                          label: Messages_Orders_Detail$DvmAdminFrontendFr.contractIDOrderV2,
                                          val: Belt_Option.mapWithDefault(order.contractID, "-", (function (x) {
                                                  if (x.length > 0) {
                                                    return x;
                                                  } else {
                                                    return "-";
                                                  }
                                                }))
                                        },
                                        tl: {
                                          hd: {
                                            label: Messages_Orders_Detail$DvmAdminFrontendFr.finalCustomer,
                                            val: Belt_Option.getWithDefault(order.detail.finalCustomerLLD, "-")
                                          },
                                          tl: {
                                            hd: {
                                              label: Messages_Orders_Detail$DvmAdminFrontendFr.finalCustomerName,
                                              val: Belt_Option.getWithDefault(order.detail.finalCustomerNameLLD, "-")
                                            },
                                            tl: {
                                              hd: Belt_Option.mapWithDefault(order.detail.budget, undefined, (function (budget) {
                                                      if (budget === "LLD") {
                                                        return {
                                                                label: Messages_Orders_Detail$DvmAdminFrontendFr.codeProtFinal,
                                                                val: Belt_Option.getWithDefault(order.detail.contractFinal, "-")
                                                              };
                                                      }
                                                      
                                                    })),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }, (function (x) {
                                      return x;
                                    }))),
                          [
                            {
                              label: Messages_Common$DvmAdminFrontendFr.model,
                              val: Belt_Option.getWithDefault(order.detail.model, "-"),
                              error: isStringEmpty(order.detail.model)
                            },
                            {
                              label: Messages_Common$DvmAdminFrontendFr.version,
                              val: Belt_Option.getWithDefault(order.detail.version, "-"),
                              error: isStringEmpty(order.detail.version)
                            },
                            {
                              label: Messages_Common$DvmAdminFrontendFr.phase,
                              val: Belt_Option.getWithDefault(order.detail.phase, "-")
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationNumber,
                              val: Belt_Option.getWithDefault(order.detail.registrationId, "-")
                            },
                            {
                              label: Messages_Common$DvmAdminFrontendFr.vin,
                              val: Belt_Option.getWithDefault(order.detail.vin, "-"),
                              error: isStringEmpty(order.detail.vin)
                            }
                          ],
                          [
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.orderDateV2,
                              val: Belt_Option.mapWithDefault(order.detail.orderDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationDateV2,
                              val: Belt_Option.mapWithDefault(order.detail.registrationDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.cancellationDate,
                              val: Belt_Option.mapWithDefault(order.detail.cancellationDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            },
                            {
                              label: Belt_Option.mapWithDefault(order.detail.delivered, Messages_Orders_Detail$DvmAdminFrontendFr.deliveryDate, (function (delivered) {
                                      if (delivered) {
                                        return Messages_Orders_Detail$DvmAdminFrontendFr.deliveryDate;
                                      } else {
                                        return Messages_Orders_Detail$DvmAdminFrontendFr.desiredDeliveryV2;
                                      }
                                    })),
                              val: Belt_Option.mapWithDefault(order.detail.deliveryDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.lastModifShortV2,
                              val: Belt_Option.mapWithDefault(order.detail.lastModificationDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.madcDate,
                              val: Belt_Option.mapWithDefault(order.detail.madcDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    })),
                              error: Belt_Option.isNone(order.detail.madcDate)
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.madaDate,
                              val: Belt_Option.mapWithDefault(order.detail.madaDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            }
                          ],
                          [
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceNumber,
                              val: Belt_Option.getWithDefault(order.detail.customerInvoiceNumber, "-")
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceClientV2,
                              val: Belt_Option.mapWithDefault(order.detail.customerInvoiceDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            },
                            {
                              label: Messages_Common$DvmAdminFrontendFr.agent,
                              val: Belt_Option.mapWithDefault(order.detail.agentCode, "-", (function (x) {
                                      if (isAgentZero(order)) {
                                        return "-";
                                      } else {
                                        return x;
                                      }
                                    }))
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.agentName,
                              val: Belt_Option.mapWithDefault(order.agentName, "-", (function (x) {
                                      if (isAgentZero(order)) {
                                        return "-";
                                      } else {
                                        return x;
                                      }
                                    }))
                            },
                            {
                              label: Messages_Common$DvmAdminFrontendFr.seller,
                              val: Belt_Option.getWithDefault(order.detail.salesmanName, "-")
                            }
                          ],
                          [
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.renaultInvoiceNumber,
                              val: Belt_Option.getWithDefault(order.detail.renaultInvoiceNumber, "-"),
                              error: isStringEmpty(order.detail.renaultInvoiceNumber)
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceDateV2,
                              val: Belt_Option.mapWithDefault(order.detail.renaultInvoiceDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    })),
                              error: Belt_Option.isNone(order.detail.renaultInvoiceDate)
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.tarifNumber,
                              val: Belt_Option.getWithDefault(order.detail.tariffNumber, "-"),
                              error: isStringEmpty(order.detail.tariffNumber)
                            },
                            {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.priceRefVNV2,
                              val: Belt_Option.mapWithDefault(order.detail.pvcHt, "-", (function (eta) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                    })),
                              error: isFloatEmptyOrZero(order.detail.pvcHt)
                            }
                          ]
                        ];
                }
                var delivered = Belt_Option.getWithDefault(order.detail.delivered, false);
                var tmp;
                var exit = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp = isStringEmpty(order.detail.shortCustomerName);
                      break;
                  
                }
                if (exit === 1) {
                  tmp = Belt_Option.isSome(order.detail.parcStartDate) ? isStringEmpty(order.detail.shortCustomerName) : false;
                }
                var tmp$1;
                var exit$1 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$1 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$1 = isStringEmpty(order.detail.model);
                      break;
                  
                }
                if (exit$1 === 1) {
                  tmp$1 = Belt_Option.isSome(order.detail.parcStartDate) ? isStringEmpty(order.detail.model) : false;
                }
                var tmp$2;
                var exit$2 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$2 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$2 = isStringEmpty(order.detail.version);
                      break;
                  
                }
                if (exit$2 === 1) {
                  tmp$2 = Belt_Option.isSome(order.detail.parcStartDate) ? isStringEmpty(order.detail.version) : false;
                }
                var tmp$3;
                var exit$3 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$3 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$3 = false;
                      break;
                  
                }
                if (exit$3 === 1) {
                  tmp$3 = Belt_Option.isSome(order.detail.parcStartDate) ? isStringEmpty(order.detail.registrationId) : false;
                }
                var tmp$4;
                var exit$4 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$4 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$4 = isStringEmpty(order.detail.vin);
                      break;
                  
                }
                if (exit$4 === 1) {
                  tmp$4 = Belt_Option.isSome(order.detail.parcStartDate) ? isStringEmpty(order.detail.vin) : false;
                }
                var tmp$5;
                var exit$5 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$5 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$5 = Belt_Option.isNone(order.detail.orderDate);
                      break;
                  
                }
                if (exit$5 === 1) {
                  tmp$5 = Belt_Option.isSome(order.detail.parcStartDate) ? Belt_Option.isNone(order.detail.orderDate) : false;
                }
                var tmp$6;
                var exit$6 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$6 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$6 = false;
                      break;
                  
                }
                if (exit$6 === 1) {
                  tmp$6 = Belt_Option.isSome(order.detail.parcStartDate) ? Belt_Option.isNone(order.detail.registrationDate) : false;
                }
                var tmp$7;
                var exit$7 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$7 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      exit$7 = 2;
                      break;
                  
                }
                switch (exit$7) {
                  case 1 :
                      tmp$7 = Belt_Option.isSome(order.detail.parcStartDate) ? Belt_Option.isNone(order.detail.deliveryDate) : false;
                      break;
                  case 2 :
                      tmp$7 = delivered || Belt_Option.isSome(order.usedCarDetail) ? Belt_Option.isNone(order.detail.deliveryDate) : false;
                      break;
                  
                }
                var tmp$8;
                var exit$8 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$8 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$8 = Belt_Option.isNone(order.detail.madcDate);
                      break;
                  
                }
                if (exit$8 === 1) {
                  tmp$8 = Belt_Option.isSome(order.detail.parcStartDate) ? Belt_Option.isNone(order.detail.madcDate) : false;
                }
                var tmp$9;
                var exit$9 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$9 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$9 = Belt_Option.isNone(order.detail.madaDate);
                      break;
                  
                }
                if (exit$9 === 1) {
                  tmp$9 = Belt_Option.isSome(order.detail.parcStartDate) ? Belt_Option.isNone(order.detail.madaDate) : false;
                }
                var tmp$10;
                var exit$10 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$10 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$10 = isStringEmpty(order.detail.renaultInvoiceNumber);
                      break;
                  
                }
                if (exit$10 === 1) {
                  tmp$10 = Belt_Option.isSome(order.detail.parcStartDate) ? isStringEmpty(order.detail.renaultInvoiceNumber) : false;
                }
                var tmp$11;
                var exit$11 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$11 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$11 = Belt_Option.isNone(order.detail.renaultInvoiceDate);
                      break;
                  
                }
                if (exit$11 === 1) {
                  tmp$11 = Belt_Option.isSome(order.detail.parcStartDate) ? Belt_Option.isNone(order.detail.renaultInvoiceDate) : false;
                }
                var tmp$12;
                var exit$12 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$12 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      tmp$12 = isStringEmpty(order.detail.tariffNumber);
                      break;
                  
                }
                if (exit$12 === 1) {
                  tmp$12 = Belt_Option.isSome(order.detail.parcStartDate) ? isStringEmpty(order.detail.tariffNumber) : false;
                }
                var match = Belt_Option.getWithDefault(order.detail.pvcHt, 0.0);
                var match$1 = Belt_Option.getWithDefault(order.detail.caht, 0.0);
                var tmp$13;
                if (match !== 0.0) {
                  var tmp$14;
                  var exit$13 = 0;
                  switch (orderType) {
                    case /* VE */2 :
                    case /* RR */4 :
                    case /* RC */5 :
                        exit$13 = 1;
                        break;
                    case /* SO */0 :
                    case /* CL */1 :
                    case /* VD */3 :
                    case /* PG */6 :
                    case /* NOTSET */7 :
                        tmp$14 = isFloatEmptyOrZero(order.detail.pvcHt);
                        break;
                    
                  }
                  if (exit$13 === 1) {
                    tmp$14 = Belt_Option.isSome(order.detail.parcStartDate) ? isFloatEmptyOrZero(order.detail.pvcHt) : false;
                  }
                  tmp$13 = {
                    label: Messages_Orders_Detail$DvmAdminFrontendFr.priceRefVNV2,
                    val: Belt_Option.mapWithDefault(order.detail.pvcHt, "-", (function (x) {
                            if (x > 0.0) {
                              return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                            } else {
                              return "-";
                            }
                          })),
                    error: tmp$14
                  };
                } else if (match$1 !== 0.0) {
                  var tmp$15;
                  var exit$14 = 0;
                  switch (orderType) {
                    case /* VE */2 :
                    case /* RR */4 :
                    case /* RC */5 :
                        exit$14 = 1;
                        break;
                    case /* SO */0 :
                    case /* CL */1 :
                    case /* VD */3 :
                    case /* PG */6 :
                    case /* NOTSET */7 :
                        exit$14 = 2;
                        break;
                    
                  }
                  switch (exit$14) {
                    case 1 :
                        tmp$15 = Belt_Option.isSome(order.detail.parcStartDate) ? isFloatEmptyOrZero(order.detail.caht) : false;
                        break;
                    case 2 :
                        tmp$15 = delivered || Belt_Option.isSome(order.usedCarDetail) ? isFloatEmptyOrZero(order.detail.caht) : false;
                        break;
                    
                  }
                  tmp$13 = {
                    label: Messages_Orders_Detail$DvmAdminFrontendFr.priceCaHt,
                    val: Belt_Option.mapWithDefault(order.detail.caht, "-", (function (x) {
                            if (x > 0.0) {
                              return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                            } else {
                              return "-";
                            }
                          })),
                    error: tmp$15
                  };
                } else {
                  var tmp$16;
                  var exit$15 = 0;
                  switch (orderType) {
                    case /* VE */2 :
                    case /* RR */4 :
                    case /* RC */5 :
                        exit$15 = 1;
                        break;
                    case /* SO */0 :
                    case /* CL */1 :
                    case /* VD */3 :
                    case /* PG */6 :
                    case /* NOTSET */7 :
                        tmp$16 = isFloatEmptyOrZero(order.detail.pvcHt);
                        break;
                    
                  }
                  if (exit$15 === 1) {
                    tmp$16 = Belt_Option.isSome(order.detail.parcStartDate) ? isFloatEmptyOrZero(order.detail.pvcHt) : false;
                  }
                  tmp$13 = {
                    label: Messages_Orders_Detail$DvmAdminFrontendFr.priceRefVNV2,
                    val: Belt_Option.mapWithDefault(order.detail.pvcHt, "-", (function (x) {
                            if (x > 0.0) {
                              return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                            } else {
                              return "-";
                            }
                          })),
                    error: tmp$16
                  };
                }
                var match$2 = Belt_Option.getWithDefault(order.detail.pvcTtc, 0.0);
                var match$3 = Belt_Option.getWithDefault(order.detail.cattc, 0.0);
                var tmp$17;
                if (match$2 !== 0.0) {
                  var tmp$18;
                  var exit$16 = 0;
                  switch (orderType) {
                    case /* VE */2 :
                    case /* RR */4 :
                    case /* RC */5 :
                        exit$16 = 1;
                        break;
                    case /* SO */0 :
                    case /* CL */1 :
                    case /* VD */3 :
                    case /* PG */6 :
                    case /* NOTSET */7 :
                        exit$16 = 2;
                        break;
                    
                  }
                  switch (exit$16) {
                    case 1 :
                        tmp$18 = Belt_Option.isSome(order.detail.parcStartDate) ? isFloatEmptyOrZero(order.detail.pvcTtc) : false;
                        break;
                    case 2 :
                        tmp$18 = delivered || Belt_Option.isSome(order.usedCarDetail) ? false : isFloatEmptyOrZero(order.detail.pvcTtc);
                        break;
                    
                  }
                  tmp$17 = {
                    label: Messages_Orders_Detail$DvmAdminFrontendFr.pvcTtc,
                    val: Belt_Option.mapWithDefault(order.detail.pvcTtc, "-", (function (x) {
                            if (x > 0.0) {
                              return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                            } else {
                              return "-";
                            }
                          })),
                    error: tmp$18
                  };
                } else if (match$3 !== 0.0) {
                  var tmp$19;
                  var exit$17 = 0;
                  switch (orderType) {
                    case /* VE */2 :
                    case /* RR */4 :
                    case /* RC */5 :
                        exit$17 = 1;
                        break;
                    case /* SO */0 :
                    case /* CL */1 :
                    case /* VD */3 :
                    case /* PG */6 :
                    case /* NOTSET */7 :
                        exit$17 = 2;
                        break;
                    
                  }
                  switch (exit$17) {
                    case 1 :
                        tmp$19 = Belt_Option.isSome(order.detail.parcStartDate) ? isFloatEmptyOrZero(order.detail.cattc) : false;
                        break;
                    case 2 :
                        tmp$19 = delivered || Belt_Option.isSome(order.usedCarDetail) ? isFloatEmptyOrZero(order.detail.cattc) : false;
                        break;
                    
                  }
                  tmp$17 = {
                    label: Messages_Orders_Detail$DvmAdminFrontendFr.priceCaTTC,
                    val: Belt_Option.mapWithDefault(order.detail.cattc, "-", (function (x) {
                            if (x > 0.0) {
                              return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                            } else {
                              return "-";
                            }
                          })),
                    error: tmp$19
                  };
                } else {
                  var tmp$20;
                  var exit$18 = 0;
                  switch (orderType) {
                    case /* VE */2 :
                    case /* RR */4 :
                    case /* RC */5 :
                        exit$18 = 1;
                        break;
                    case /* SO */0 :
                    case /* CL */1 :
                    case /* VD */3 :
                    case /* PG */6 :
                    case /* NOTSET */7 :
                        exit$18 = 2;
                        break;
                    
                  }
                  switch (exit$18) {
                    case 1 :
                        tmp$20 = Belt_Option.isSome(order.detail.parcStartDate) ? isFloatEmptyOrZero(order.detail.pvcTtc) : false;
                        break;
                    case 2 :
                        tmp$20 = delivered || Belt_Option.isSome(order.usedCarDetail) ? false : isFloatEmptyOrZero(order.detail.pvcTtc);
                        break;
                    
                  }
                  tmp$17 = {
                    label: Messages_Orders_Detail$DvmAdminFrontendFr.pvcTtc,
                    val: Belt_Option.mapWithDefault(order.detail.pvcTtc, "-", (function (x) {
                            if (x > 0.0) {
                              return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                            } else {
                              return "-";
                            }
                          })),
                    error: tmp$20
                  };
                }
                var tmp$21;
                var exit$19 = 0;
                switch (orderType) {
                  case /* VE */2 :
                  case /* RR */4 :
                  case /* RC */5 :
                      exit$19 = 1;
                      break;
                  case /* SO */0 :
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* PG */6 :
                  case /* NOTSET */7 :
                      exit$19 = 2;
                      break;
                  
                }
                switch (exit$19) {
                  case 1 :
                      tmp$21 = Belt_Option.isSome(order.detail.parcStartDate) ? isFloatEmptyOrZero(order.detail.pvrHt) : false;
                      break;
                  case 2 :
                      tmp$21 = delivered || Belt_Option.isSome(order.usedCarDetail) ? isFloatEmptyOrZero(order.detail.pvrHt) : false;
                      break;
                  
                }
                var tmp$22;
                switch (orderType) {
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* RR */4 :
                  case /* RC */5 :
                  case /* PG */6 :
                      tmp$22 = Messages_Common$DvmAdminFrontendFr.discountTTC;
                      break;
                  case /* SO */0 :
                  case /* VE */2 :
                  case /* NOTSET */7 :
                      tmp$22 = Messages_Common$DvmAdminFrontendFr.discount;
                      break;
                  
                }
                var tmp$23;
                switch (orderType) {
                  case /* CL */1 :
                  case /* VD */3 :
                  case /* RR */4 :
                  case /* RC */5 :
                  case /* PG */6 :
                      tmp$23 = /* CustomerDiscount */0;
                      break;
                  case /* SO */0 :
                  case /* VE */2 :
                  case /* NOTSET */7 :
                      tmp$23 = undefined;
                      break;
                  
                }
                return [
                        [
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.customerName,
                            val: Belt_Option.getWithDefault(order.detail.shortCustomerName, "-"),
                            error: tmp
                          },
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.clientFirstName,
                            val: Belt_Option.getWithDefault(order.detail.clientFirstName, "-")
                          }
                        ],
                        Belt_Array.concat([
                              {
                                label: Messages_Common$DvmAdminFrontendFr.model,
                                val: Belt_Option.getWithDefault(order.detail.model, "-"),
                                error: tmp$1
                              },
                              {
                                label: Messages_Common$DvmAdminFrontendFr.version,
                                val: Belt_Option.getWithDefault(order.detail.version, "-"),
                                error: tmp$2
                              },
                              {
                                label: Messages_Common$DvmAdminFrontendFr.phase,
                                val: Belt_Option.getWithDefault(order.detail.phase, "-")
                              },
                              {
                                label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationNumber,
                                val: Belt_Option.getWithDefault(order.detail.registrationId, "-"),
                                error: tmp$3
                              },
                              {
                                label: Messages_Common$DvmAdminFrontendFr.vin,
                                val: Belt_Option.getWithDefault(order.detail.vin, "-"),
                                error: tmp$4
                              }
                            ], orderType === /* VE */2 || orderType === /* RC */5 || orderType === /* RR */4 ? [
                                {
                                  label: Messages_Orders_Detail$DvmAdminFrontendFr.dateInputParc,
                                  val: Belt_Option.mapWithDefault(order.detail.parcStartDate, "-", (function (param) {
                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                        })),
                                  error: Belt_Option.isNone(order.detail.parcStartDate)
                                },
                                {
                                  label: Messages_Orders_Detail$DvmAdminFrontendFr.dateOutputParc,
                                  val: Belt_Option.mapWithDefault(order.detail.parcEndDate, "-", (function (param) {
                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                        })),
                                  edit: /* ParcEndDate */1
                                }
                              ] : []),
                        [
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.orderDateV2,
                            val: Belt_Option.mapWithDefault(order.detail.orderDate, "-", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  })),
                            error: tmp$5
                          },
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationDateV2,
                            val: Belt_Option.mapWithDefault(order.detail.registrationDate, "-", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  })),
                            error: tmp$6
                          },
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.cancellationDate,
                            val: Belt_Option.mapWithDefault(order.detail.cancellationDate, "-", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  }))
                          },
                          {
                            label: Belt_Option.mapWithDefault(order.detail.delivered, Messages_Orders_Detail$DvmAdminFrontendFr.deliveryDate, (function (delivered) {
                                    if (delivered) {
                                      return Messages_Orders_Detail$DvmAdminFrontendFr.deliveryDate;
                                    } else {
                                      return Messages_Orders_Detail$DvmAdminFrontendFr.desiredDeliveryV2;
                                    }
                                  })),
                            val: Belt_Option.mapWithDefault(order.detail.deliveryDate, "-", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  })),
                            error: tmp$7
                          },
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.lastModifShortV2,
                            val: Belt_Option.mapWithDefault(order.detail.lastModificationDate, "-", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  }))
                          },
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.madcDate,
                            val: Belt_Option.mapWithDefault(order.detail.madcDate, "-", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  })),
                            error: tmp$8
                          },
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.madaDate,
                            val: Belt_Option.mapWithDefault(order.detail.madaDate, "-", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  })),
                            error: tmp$9
                          }
                        ],
                        [
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceNumber,
                            val: Belt_Option.getWithDefault(order.detail.customerInvoiceNumber, "-")
                          },
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceClientV2,
                            val: Belt_Option.mapWithDefault(order.detail.customerInvoiceDate, "-", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  }))
                          },
                          {
                            label: Messages_Common$DvmAdminFrontendFr.agent,
                            val: Belt_Option.mapWithDefault(order.detail.agentCode, "-", (function (x) {
                                    if (isAgentZero(order)) {
                                      return "-";
                                    } else {
                                      return x;
                                    }
                                  })),
                            error: orderType !== 3 ? false : Belt_Option.mapWithDefault(order.detail.agentCode, true, (function (param) {
                                      return isAgentZero(order);
                                    }))
                          },
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.agentName,
                            val: Belt_Option.mapWithDefault(order.agentName, "-", (function (x) {
                                    if (isAgentZero(order)) {
                                      return "-";
                                    } else {
                                      return x;
                                    }
                                  }))
                          },
                          {
                            label: Messages_Common$DvmAdminFrontendFr.seller,
                            val: Belt_Option.getWithDefault(order.detail.salesmanName, "-")
                          }
                        ],
                        [
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.renaultInvoiceNumber,
                            val: Belt_Option.getWithDefault(order.detail.renaultInvoiceNumber, "-"),
                            error: tmp$10
                          },
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceDateV2,
                            val: Belt_Option.mapWithDefault(order.detail.renaultInvoiceDate, "-", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  })),
                            error: tmp$11
                          },
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.tarifNumber,
                            val: Belt_Option.getWithDefault(order.detail.tariffNumber, "-"),
                            error: tmp$12
                          },
                          tmp$13,
                          tmp$17,
                          {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.pvrHt,
                            val: Belt_Option.mapWithDefault(order.detail.pvrHt, "-", (function (eta) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                  })),
                            error: tmp$21
                          },
                          {
                            label: tmp$22,
                            val: Belt_Option.mapWithDefault(order.detail.discount, "-", (function (eta) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                  })),
                            show: User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) || selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole),
                            edit: tmp$23
                          },
                          {
                            label: Messages_FinancialCodes$DvmAdminFrontendFr.codeId,
                            val: Belt_Option.getWithDefault(order.detail.financialCode, "-"),
                            show: User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) || selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)
                          }
                        ]
                      ];
              }));
}

var DealerEditTypes;

export {
  DealerEditTypes ,
  isAgentZero ,
  isStringEmpty ,
  isFloatEmptyOrZero ,
  headerArray ,
  orderDetail ,
}
/* No side effect */
