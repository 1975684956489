// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as EnvVar$DvmAdminFrontendFr from "../../bindings/global/EnvVar.bs.js";
import * as App_Api$DvmAdminFrontendFr from "../../common/App_Api.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../context/user/User_Types_Utils.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../common/styles/App_Styles_Common.bs.js";
import * as Dealer_Search_Api$DvmAdminFrontendFr from "../dealer/search/Dealer_Search_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../common/components/App_OverlaySpinner.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../common/components/input/App_SelectFormInput.bs.js";
import * as App_CompanyFormInput$DvmAdminFrontendFr from "../../common/components/input/App_CompanyFormInput.bs.js";
import * as Messages_Bonuses_List$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_List.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../common/components/input/App_DateInputFormField.bs.js";
import * as Messages_Orders_Search$DvmAdminFrontendFr from "../../intl/messages/orders/Messages_Orders_Search.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../common/components/input/App_DoubleSelectFormInput.bs.js";
import * as Bonus_Anomalies_Search_Api$DvmAdminFrontendFr from "./Bonus_Anomalies_Search_Api.bs.js";
import * as Messages_Bonuses_Anomalies$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_Anomalies.bs.js";
import * as Bonus_Anomalies_Search_Form$DvmAdminFrontendFr from "./Bonus_Anomalies_Search_Form.bs.js";

function Bonus_Anomalies_Search_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var setParamsList = Props.setParamsList;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealers = match$4[1];
  var drAndDealers = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$5[1];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match$6[1];
  var modelVersions = match$6[0];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAvailableReasons = match$7[1];
  var availableReasons = match$7[0];
  var match$8 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setManagers = match$8[1];
  var managers = match$8[0];
  var match$9 = React.useState(function () {
        return "";
      });
  var setDealerSearchInput = match$9[1];
  var form = Bonus_Anomalies_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match[0].bonusAnomaliesFilter, Bonus_Anomalies_Search_Form$DvmAdminFrontendFr.initialState), (function (param, submissionCallbacks) {
          var params_0 = param.dr;
          var params_1 = {
            hd: param.dealerId,
            tl: {
              hd: param.siren,
              tl: {
                hd: param.showDealerHistory,
                tl: {
                  hd: param.budgetType,
                  tl: {
                    hd: param.actionType,
                    tl: {
                      hd: param.model,
                      tl: {
                        hd: param.version,
                        tl: {
                          hd: param.budgetYear,
                          tl: {
                            hd: param.orderStatus,
                            tl: {
                              hd: param.accountManagerIPN,
                              tl: {
                                hd: param.delivered,
                                tl: {
                                  hd: param.errorDescription,
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
          var params = {
            hd: params_0,
            tl: params_1
          };
          Curry._1(setParamsList, (function (param) {
                  return params;
                }));
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(params, (function (queryParam) {
                                return Belt_Option.map(queryParam, (function (x) {
                                              return x.en;
                                            }));
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
          App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealers, form.input.showDealerHistory, selectedRole);
          Dealer_Search_Api$DvmAdminFrontendFr.queryForManagerSelect(alert, userManager, setManagers, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, undefined, undefined, undefined);
        }), []);
  React.useEffect((function () {
          Bonus_Anomalies_Search_Api$DvmAdminFrontendFr.fetchAvailableReasons(userManager, setAvailableReasons, selectedRole, form.input.budgetType, form.input.actionType);
        }), [
        form.input.budgetType,
        form.input.actionType
      ]);
  React.useEffect((function () {
          if (form.input.errorDescription.length > 0) {
            App_Types_Result$DvmAdminFrontendFr.mapWithDefault(availableReasons, undefined, (function (x) {
                    if (Belt_List.has(x, form.input.errorDescription, (function (a, b) {
                              return a === b;
                            }))) {
                      return ;
                    } else {
                      return Curry._2(form.updateErrorDescription, (function (input, errorDescription) {
                                    return {
                                            dr: input.dr,
                                            dealerId: input.dealerId,
                                            budgetType: input.budgetType,
                                            actionType: input.actionType,
                                            model: input.model,
                                            version: input.version,
                                            budgetYear: input.budgetYear,
                                            siren: input.siren,
                                            orderStatus: input.orderStatus,
                                            accountManagerIPN: input.accountManagerIPN,
                                            delivered: input.delivered,
                                            errorDescription: errorDescription,
                                            showDealerHistory: input.showDealerHistory
                                          };
                                  }), "");
                    }
                  }));
          }
          
        }), [availableReasons]);
  React.useEffect((function () {
          Curry._1(form.submit, undefined);
        }), []);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.budget,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: form.input.budgetType,
        onChange: Curry._1(form.updateBudgetType, (function (input, budgetType) {
                return {
                        dr: input.dr,
                        dealerId: input.dealerId,
                        budgetType: budgetType,
                        actionType: input.actionType,
                        model: input.model,
                        version: input.version,
                        budgetYear: input.budgetYear,
                        siren: input.siren,
                        orderStatus: input.orderStatus,
                        accountManagerIPN: input.accountManagerIPN,
                        delivered: input.delivered,
                        errorDescription: input.errorDescription,
                        showDealerHistory: input.showDealerHistory
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$5[0], /* [] */0), (function (x) {
              return {
                      label: {
                        TAG: /* String */3,
                        _0: x
                      },
                      value: x
                    };
            }))
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
    limitTags: 3,
    smallChips: true
  };
  if (form.budgetTypeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.budgetTypeResult);
  }
  var tmp$1 = {
    label: Messages_Bonuses_List$DvmAdminFrontendFr.flashType,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.actionType,
        onChange: Curry._1(form.updateActionType, (function (input, actionType) {
                return {
                        dr: input.dr,
                        dealerId: input.dealerId,
                        budgetType: input.budgetType,
                        actionType: actionType,
                        model: input.model,
                        version: input.version,
                        budgetYear: input.budgetYear,
                        siren: input.siren,
                        orderStatus: input.orderStatus,
                        accountManagerIPN: input.accountManagerIPN,
                        delivered: input.delivered,
                        errorDescription: input.errorDescription,
                        showDealerHistory: input.showDealerHistory
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: {
        hd: "Budget",
        tl: {
          hd: "STOC",
          tl: {
            hd: "REPR",
            tl: /* [] */0
          }
        }
      }
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.actionTypeResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.actionTypeResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.year,
    value: form.input.budgetYear,
    onChange: Curry._1(form.updateBudgetYear, (function (input, budgetYear) {
            return {
                    dr: input.dr,
                    dealerId: input.dealerId,
                    budgetType: input.budgetType,
                    actionType: input.actionType,
                    model: input.model,
                    version: input.version,
                    budgetYear: budgetYear,
                    siren: input.siren,
                    orderStatus: input.orderStatus,
                    accountManagerIPN: input.accountManagerIPN,
                    delivered: input.delivered,
                    errorDescription: input.errorDescription,
                    showDealerHistory: input.showDealerHistory
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    variant: /* YEAR */0
  };
  if (form.budgetYearResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.budgetYearResult);
  }
  var tmp$3 = {
    label: Messages_Orders_Search$DvmAdminFrontendFr.orderStatusLabel,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: form.input.orderStatus,
        onChange: Curry._1(form.updateOrderStatus, (function (input, orderStatus) {
                return {
                        dr: input.dr,
                        dealerId: input.dealerId,
                        budgetType: input.budgetType,
                        actionType: input.actionType,
                        model: input.model,
                        version: input.version,
                        budgetYear: input.budgetYear,
                        siren: input.siren,
                        orderStatus: orderStatus,
                        accountManagerIPN: input.accountManagerIPN,
                        delivered: input.delivered,
                        errorDescription: input.errorDescription,
                        showDealerHistory: input.showDealerHistory
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: Belt_List.keepMap({
            hd: {
              label: {
                TAG: /* Message */0,
                _0: Messages_Orders_Search$DvmAdminFrontendFr.statusComplete
              },
              value: "Complete"
            },
            tl: {
              hd: {
                label: {
                  TAG: /* Message */0,
                  _0: Messages_Orders_Search$DvmAdminFrontendFr.statusIncomplete
                },
                value: "Incomplete"
              },
              tl: {
                hd: {
                  label: {
                    TAG: /* Message */0,
                    _0: Messages_Orders_Search$DvmAdminFrontendFr.statusCanceled
                  },
                  value: "Annulee"
                },
                tl: {
                  hd: selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? undefined : ({
                        label: {
                          TAG: /* Message */0,
                          _0: Messages_Orders_Search$DvmAdminFrontendFr.statusReparceled
                        },
                        value: "Remembrement"
                      }),
                  tl: {
                    hd: EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? undefined : ({
                          label: {
                            TAG: /* Message */0,
                            _0: Messages_Orders_Search$DvmAdminFrontendFr.parcPending
                          },
                          value: "Attente entrée PARC"
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }, (function (x) {
              return x;
            }))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputExtraLong,
    limitTags: 3,
    smallChips: true
  };
  if (form.orderStatusResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.orderStatusResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreBonusAnomaliesFilter */28,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                      show: App_Types_Result$DvmAdminFrontendFr.isPending(drAndDealers),
                      children: React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            direction: "column",
                            item: true,
                            style: {
                              width: "fit-content"
                            }
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                      labelParent: Messages_Common$DvmAdminFrontendFr.territory,
                                      labelChild: Messages_Common$DvmAdminFrontendFr.dealerID,
                                      valueParent: {
                                        TAG: /* Multiple */1,
                                        _0: {
                                          value: form.input.dr,
                                          onChange: Curry._1(form.updateDr, (function (input, dr) {
                                                  return {
                                                          dr: dr,
                                                          dealerId: input.dealerId,
                                                          budgetType: input.budgetType,
                                                          actionType: input.actionType,
                                                          model: input.model,
                                                          version: input.version,
                                                          budgetYear: input.budgetYear,
                                                          siren: input.siren,
                                                          orderStatus: input.orderStatus,
                                                          accountManagerIPN: input.accountManagerIPN,
                                                          delivered: input.delivered,
                                                          errorDescription: input.errorDescription,
                                                          showDealerHistory: input.showDealerHistory
                                                        };
                                                }))
                                        }
                                      },
                                      valueChild: {
                                        TAG: /* Multiple */1,
                                        _0: {
                                          value: form.input.dealerId,
                                          onChange: (function (val) {
                                              Curry._1(setDealerSearchInput, (function (param) {
                                                      return "";
                                                    }));
                                              Curry._2(form.updateDealerId, (function (input, dealerId) {
                                                      return {
                                                              dr: input.dr,
                                                              dealerId: dealerId,
                                                              budgetType: input.budgetType,
                                                              actionType: input.actionType,
                                                              model: input.model,
                                                              version: input.version,
                                                              budgetYear: input.budgetYear,
                                                              siren: input.siren,
                                                              orderStatus: input.orderStatus,
                                                              accountManagerIPN: input.accountManagerIPN,
                                                              delivered: input.delivered,
                                                              errorDescription: input.errorDescription,
                                                              showDealerHistory: input.showDealerHistory
                                                            };
                                                    }), val);
                                            })
                                        }
                                      },
                                      options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(drAndDealers, /* [] */0),
                                      classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                                      classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputLong,
                                      limitTagsParent: 2,
                                      limitTagsChild: 2,
                                      selectParentIfOnlyOne: true,
                                      selectChildIfOnlyOne: true,
                                      onSearchChild: (function (val) {
                                          Curry._1(setDealerSearchInput, (function (param) {
                                                  return val.trim().slice(0, 6);
                                                }));
                                        }),
                                      inputValueChild: match$9[0]
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(Core.FormControlLabel, {
                                      classes: {
                                        label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                      },
                                      control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                            checked: form.input.showDealerHistory,
                                            onClick: (function ($$event) {
                                                Curry._2(form.updateShowDealerHistory, (function (input, showDealerHistory) {
                                                        return {
                                                                dr: input.dr,
                                                                dealerId: input.dealerId,
                                                                budgetType: input.budgetType,
                                                                actionType: input.actionType,
                                                                model: input.model,
                                                                version: input.version,
                                                                budgetYear: input.budgetYear,
                                                                siren: input.siren,
                                                                orderStatus: input.orderStatus,
                                                                accountManagerIPN: input.accountManagerIPN,
                                                                delivered: input.delivered,
                                                                errorDescription: input.errorDescription,
                                                                showDealerHistory: showDealerHistory
                                                              };
                                                      }), $$event.target.checked);
                                              })
                                          }),
                                      label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.showDealerHistory)
                                    }),
                                item: true
                              }))
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(managers),
                            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                  label: Messages_Bonuses_Anomalies$DvmAdminFrontendFr.dealerKeyAccountManager,
                                  value: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.accountManagerIPN,
                                      onChange: Curry._1(form.updateAccountManagerIPN, (function (input, accountManagerIPN) {
                                              return {
                                                      dr: input.dr,
                                                      dealerId: input.dealerId,
                                                      budgetType: input.budgetType,
                                                      actionType: input.actionType,
                                                      model: input.model,
                                                      version: input.version,
                                                      budgetYear: input.budgetYear,
                                                      siren: input.siren,
                                                      orderStatus: input.orderStatus,
                                                      accountManagerIPN: accountManagerIPN,
                                                      delivered: input.delivered,
                                                      errorDescription: input.errorDescription,
                                                      showDealerHistory: input.showDealerHistory
                                                    };
                                            }))
                                    }
                                  },
                                  options: {
                                    TAG: /* Labeled */1,
                                    _0: Belt_List.map(Belt_List.sort(Belt_List.keep(App_Types_Result$DvmAdminFrontendFr.getWithDefault(managers, /* [] */0), (function (x) {
                                                    if (Belt_Option.getWithDefault(x.paramOne, "").length > 0) {
                                                      return Belt_Option.getWithDefault(x.paramTwo, "").length > 0;
                                                    } else {
                                                      return false;
                                                    }
                                                  })), (function (a, b) {
                                                return Caml.string_compare(Belt_Option.getWithDefault(a.paramTwo, ""), Belt_Option.getWithDefault(b.paramTwo, ""));
                                              })), (function (x) {
                                            return {
                                                    label: {
                                                      TAG: /* String */3,
                                                      _0: Belt_Option.getWithDefault(x.paramTwo, "") + (
                                                        Belt_Option.getWithDefault(x.paramOne, "").length > 0 ? " (" + Belt_Option.getWithDefault(x.paramOne, "") + ")" : ""
                                                      )
                                                    },
                                                    value: Belt_Option.getWithDefault(x.paramOne, "")
                                                  };
                                          }))
                                  },
                                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions),
                            children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                  labelParent: Messages_Common$DvmAdminFrontendFr.model,
                                  labelChild: Messages_Common$DvmAdminFrontendFr.version,
                                  valueParent: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.model,
                                      onChange: Curry._1(form.updateModel, (function (input, model) {
                                              return {
                                                      dr: input.dr,
                                                      dealerId: input.dealerId,
                                                      budgetType: input.budgetType,
                                                      actionType: input.actionType,
                                                      model: model,
                                                      version: "",
                                                      budgetYear: input.budgetYear,
                                                      siren: input.siren,
                                                      orderStatus: input.orderStatus,
                                                      accountManagerIPN: input.accountManagerIPN,
                                                      delivered: input.delivered,
                                                      errorDescription: input.errorDescription,
                                                      showDealerHistory: input.showDealerHistory
                                                    };
                                            }))
                                    }
                                  },
                                  valueChild: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.version,
                                      onChange: Curry._1(form.updateVersion, (function (input, version) {
                                              return {
                                                      dr: input.dr,
                                                      dealerId: input.dealerId,
                                                      budgetType: input.budgetType,
                                                      actionType: input.actionType,
                                                      model: input.model,
                                                      version: version,
                                                      budgetYear: input.budgetYear,
                                                      siren: input.siren,
                                                      orderStatus: input.orderStatus,
                                                      accountManagerIPN: input.accountManagerIPN,
                                                      delivered: input.delivered,
                                                      errorDescription: input.errorDescription,
                                                      showDealerHistory: input.showDealerHistory
                                                    };
                                            }))
                                    }
                                  },
                                  options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersions, /* [] */0),
                                  classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                                  classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_CompanyFormInput$DvmAdminFrontendFr.make, {
                            onChange: (function (siren) {
                                Curry._2(form.updateSiren, (function (input, siren) {
                                        return {
                                                dr: input.dr,
                                                dealerId: input.dealerId,
                                                budgetType: input.budgetType,
                                                actionType: input.actionType,
                                                model: input.model,
                                                version: input.version,
                                                budgetYear: input.budgetYear,
                                                siren: siren,
                                                orderStatus: input.orderStatus,
                                                accountManagerIPN: input.accountManagerIPN,
                                                delivered: input.delivered,
                                                errorDescription: input.errorDescription,
                                                showDealerHistory: input.showDealerHistory
                                              };
                                      }), siren.trim());
                              }),
                            value: form.input.siren,
                            companies: match$3[0],
                            setCompanies: match$3[1],
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                            companyType: "siren"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(availableReasons),
                            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                  label: Messages_Bonuses_Anomalies$DvmAdminFrontendFr.errorDescription,
                                  value: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.errorDescription,
                                      onChange: Curry._1(form.updateErrorDescription, (function (input, errorDescription) {
                                              return {
                                                      dr: input.dr,
                                                      dealerId: input.dealerId,
                                                      budgetType: input.budgetType,
                                                      actionType: input.actionType,
                                                      model: input.model,
                                                      version: input.version,
                                                      budgetYear: input.budgetYear,
                                                      siren: input.siren,
                                                      orderStatus: input.orderStatus,
                                                      accountManagerIPN: input.accountManagerIPN,
                                                      delivered: input.delivered,
                                                      errorDescription: errorDescription,
                                                      showDealerHistory: input.showDealerHistory
                                                    };
                                            }))
                                    }
                                  },
                                  options: {
                                    TAG: /* Unlabeled */0,
                                    _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(availableReasons, /* [] */0)
                                  },
                                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$3),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Bonuses_Anomalies$DvmAdminFrontendFr.anomalieDelivered,
                            options: {
                              hd: {
                                label: {
                                  TAG: /* Message */0,
                                  _0: Messages_Common$DvmAdminFrontendFr.everything
                                },
                                value: ""
                              },
                              tl: {
                                hd: {
                                  label: {
                                    TAG: /* Message */0,
                                    _0: Messages_Common$DvmAdminFrontendFr.yes
                                  },
                                  value: "TRUE"
                                },
                                tl: {
                                  hd: {
                                    label: {
                                      TAG: /* Message */0,
                                      _0: Messages_Common$DvmAdminFrontendFr.no
                                    },
                                    value: "FALSE"
                                  },
                                  tl: /* [] */0
                                }
                              }
                            },
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.delivered,
                                onChange: Curry._1(form.updateDelivered, (function (input, delivered) {
                                        return {
                                                dr: input.dr,
                                                dealerId: input.dealerId,
                                                budgetType: input.budgetType,
                                                actionType: input.actionType,
                                                model: input.model,
                                                version: input.version,
                                                budgetYear: input.budgetYear,
                                                siren: input.siren,
                                                orderStatus: input.orderStatus,
                                                accountManagerIPN: input.accountManagerIPN,
                                                delivered: delivered,
                                                errorDescription: input.errorDescription,
                                                showDealerHistory: input.showDealerHistory
                                              };
                                      }))
                              }
                            },
                            id: "delivered",
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var InputStyles;

var make = Bonus_Anomalies_Search_Filter;

export {
  Form ,
  Api ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
