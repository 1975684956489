// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_SelectFormInput.bs.js";
import * as Contract_Search_Api$DvmAdminFrontendFr from "./Contract_Search_Api.bs.js";
import * as App_CompanyFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_CompanyFormInput.bs.js";
import * as Contract_Search_Form$DvmAdminFrontendFr from "./Contract_Search_Form.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as Messages_Contract_List$DvmAdminFrontendFr from "../../../intl/messages/contract/Messages_Contract_List.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Contract_Search_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var setParamsList = Props.setParamsList;
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFetchedManagerIds = match$1[1];
  var fetchedManagerIds = match$1[0];
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setContractIds = match$3[1];
  var contractIds = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setContractIdsOrdered = match$4[1];
  var match$5 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$6 = match$5[0];
  var userManager = match$6.userManager;
  var selectedRole = match$6.selectedRole;
  var match$7 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$7[1];
  var form = Contract_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.mapWithDefault(match$7[0].contractsFilter, Contract_Search_Form$DvmAdminFrontendFr.initialState, (function (filter) {
              return filter;
            })), (function (param, submissionCallbacks) {
          var paramsList_0 = param.budget;
          var paramsList_1 = {
            hd: param.contractID,
            tl: {
              hd: param.siren,
              tl: {
                hd: param.managerID,
                tl: {
                  hd: param.startDate,
                  tl: {
                    hd: param.endDate,
                    tl: /* [] */0
                  }
                }
              }
            }
          };
          var paramsList = {
            hd: paramsList_0,
            tl: paramsList_1
          };
          Curry._1(setParamsList, (function (param) {
                  return paramsList;
                }));
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(paramsList, (function (queryParam) {
                                return Belt_Option.map(queryParam, (function (x) {
                                              return x.en;
                                            }));
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          if (typeof fetchedManagerIds !== "number" && fetchedManagerIds.TAG === /* Error */1) {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: fetchedManagerIds._0
                      }
                    }));
          }
          
        }), [fetchedManagerIds]);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
          Contract_Search_Api$DvmAdminFrontendFr.fetchManagers(userManager, setFetchedManagerIds, selectedRole);
          Curry._1(form.submit, undefined);
        }), []);
  React.useEffect((function () {
          Contract_Search_Api$DvmAdminFrontendFr.fetchContractsByBudget(userManager, setContractIds, alert, selectedRole, form.input.budget);
        }), [form.input.budget]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(contractIds, undefined, (function (x) {
                  Curry._1(setContractIdsOrdered, (function (param) {
                          return Belt_List.sort(Belt_List.map(x, (function (x) {
                                            return x.paramOne;
                                          })), Caml.string_compare);
                        }));
                }));
        }), [contractIds]);
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreContractsFilter */11,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.budget,
                            options: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$2[0], /* [] */0), (function (x) {
                                    return {
                                            label: {
                                              TAG: /* String */3,
                                              _0: x
                                            },
                                            value: x
                                          };
                                  })),
                            value: {
                              TAG: /* Multiple */1,
                              _0: {
                                value: form.input.budget,
                                onChange: Curry._1(form.updateBudget, (function (input, budget) {
                                        return {
                                                budget: budget,
                                                contractID: input.contractID,
                                                siren: input.siren,
                                                managerID: input.managerID,
                                                startDate: input.startDate,
                                                endDate: input.endDate
                                              };
                                      }))
                              }
                            },
                            id: "budget",
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(contractIds),
                            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                  label: Messages_Contract_List$DvmAdminFrontendFr.contractID,
                                  value: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.contractID,
                                      onChange: Curry._1(form.updateContractID, (function (input, contractID) {
                                              return {
                                                      budget: input.budget,
                                                      contractID: contractID,
                                                      siren: input.siren,
                                                      managerID: input.managerID,
                                                      startDate: input.startDate,
                                                      endDate: input.endDate
                                                    };
                                            }))
                                    }
                                  },
                                  options: {
                                    TAG: /* Unlabeled */0,
                                    _0: match$4[0]
                                  },
                                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_CompanyFormInput$DvmAdminFrontendFr.make, {
                            onChange: (function (siren) {
                                Curry._2(form.updateSiren, (function (input, siren) {
                                        return {
                                                budget: input.budget,
                                                contractID: input.contractID,
                                                siren: siren,
                                                managerID: input.managerID,
                                                startDate: input.startDate,
                                                endDate: input.endDate
                                              };
                                      }), siren.trim());
                              }),
                            value: form.input.siren,
                            companies: match[0],
                            setCompanies: match[1],
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                            companyType: "siren"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Contract_List$DvmAdminFrontendFr.managerIPN,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.managerID,
                                onChange: Curry._1(form.updateManagerID, (function (input, managerID) {
                                        return {
                                                budget: input.budget,
                                                contractID: input.contractID,
                                                siren: input.siren,
                                                managerID: managerID,
                                                startDate: input.startDate,
                                                endDate: input.endDate
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Labeled */1,
                              _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(fetchedManagerIds, /* [] */0, (function (x) {
                                      return Belt_List.map(x, (function (x) {
                                                    return {
                                                            label: {
                                                              TAG: /* String */3,
                                                              _0: x.paramOne + Belt_Option.mapWithDefault(x.paramTwo, "", (function (x) {
                                                                      if (x.length > 0) {
                                                                        return " (" + x + ")";
                                                                      } else {
                                                                        return "";
                                                                      }
                                                                    }))
                                                            },
                                                            value: x.paramOne
                                                          };
                                                  }));
                                    }))
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.startDate,
                            value: form.input.startDate,
                            onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
                                    return {
                                            budget: input.budget,
                                            contractID: input.contractID,
                                            siren: input.siren,
                                            managerID: input.managerID,
                                            startDate: startDate,
                                            endDate: input.endDate
                                          };
                                  })),
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.endDate,
                            value: form.input.endDate,
                            onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
                                    return {
                                            budget: input.budget,
                                            contractID: input.contractID,
                                            siren: input.siren,
                                            managerID: input.managerID,
                                            startDate: input.startDate,
                                            endDate: endDate
                                          };
                                  })),
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var InputStyles;

var Form;

var Api;

var make = Contract_Search_Filter;

export {
  InputStyles ,
  Form ,
  Api ,
  make ,
}
/* react Not a pure module */
